<template>
  <div class="flex w-full flex-col items-start gap-3">
    <div class="sub-settings-header central flex-row">
      <div class="font-medium">{{ t("common.productSelectionHeading") }}</div>
      <UITooltip width="300px" trigger="hover" placement="top">
        <template #trigger>
          <InfoCircleIcon class="h-4 w-4 text-gray-400"></InfoCircleIcon>
        </template>
        {{ t("common.productSelectionSubtext") }}
      </UITooltip>
    </div>
    <div class="flex w-full flex-col items-start justify-between">
      <div class="flex w-full items-center gap-3">
        <div
          v-for="(layout, index) in layouts"
          :key="index"
          class="flex w-full cursor-pointer flex-col items-center"
          @click="layoutChange(layout.value)"
        >
          <div
            class="flex h-11 w-full items-center justify-center gap-2 rounded-lg border border-gray-300 px-6 py-4 pl-3 font-medium text-gray-700 transition-colors duration-200"
            :class="{
              'layout-card-active bg-gray-100 text-primary-700': currentLayout === layout.value,
            }"
          >
            <UIRadio
              v-if="layout.value === 2"
              id="radio"
              :checked="currentLayout === layout.value"
            />
            <UICheckbox
              v-if="layout.value === 1"
              id="check"
              :checked="currentLayout === layout.value"
            />
            {{ layout.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UICheckbox, UIRadio, UITooltip } from "@gohighlevel/ghl-ui";
import { InfoCircleIcon } from "@gohighlevel/ghl-icons/24/outline";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const store = useAppStore();

const layouts = ref([
  {
    name: t("common.multi"),
    value: 1,
  },
  { name: t("common.single"), value: 2 },
]);
const currentLayout = computed(() => {
  return store.payment.selectionType === "checkbox" ? 1 : 2;
});

const layoutChange = (value) => {
  store.payment.selectionType = value === 1 ? "checkbox" : "radio";
  store.addToHistory();
  store.anyUnsavedChanges = true;
};
</script>
<style scoped>
.layout-card-active {
  border: 2px solid #155eef !important;
}
.central {
  align-items: center !important;
}
</style>
