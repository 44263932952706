import GridDotBlank from "@/assets/icons/grid-dots-blank.svg?url";
import LeftArrow from "@/assets/icons/left-arrow.svg";
import RightArrow from "@/assets/icons/right-arrow.svg";
import TopArrow from "@/assets/icons/top-arrow.svg";
import PercentageBarSelectedImage from "@/assets/img/percentage-bar-selected.png";
import PercentageBarImage from "@/assets/img/percentage-bar.png";
import StepsSelectedImage from "@/assets/img/steps-selected.png";
import StepsImage from "@/assets/img/steps.png";
import { Maximize02Icon, SlashCircle01Icon, SquareIcon } from "@gohighlevel/ghl-icons/24/outline";
import { h } from "vue";
import { i18n } from "../plugin/vue-i18n";

const { t } = i18n.global;

// Embedded forms
export const STICKY_SIDEBAR = "STICKY_SIDEBAR";
export const POLITE_SLIDE_IN = "POLITE_SLIDE_IN";
export const POPUP = "POPUP";
export const INLINE = "INLINE";
// FORM LAYOUTS URLS
export const FORM_LAYOUT_URLS = {
  STICKY_SIDEBAR: "https://stcdn.leadconnectorhq.com/forms/img/sticky-sidebar.png",
  POLITE_SLIDE_IN: "https://stcdn.leadconnectorhq.com/forms/img/polite-slide-in.png",
  STICKY_SIDEBAR_RIGHT: "https://stcdn.leadconnectorhq.com/forms/img/polite-slide-in-right.png",
  POLITE_SLIDE_IN_LEFT: "https://stcdn.leadconnectorhq.com/forms/img/sticky-sidebar-left.png",
  POPUP: "https://stcdn.leadconnectorhq.com/forms/img/popup-layout.png",
  INLINE: "https://stcdn.leadconnectorhq.com/forms/img/inline-layout.png",
};
export const CARD_LAYOUTS = [
  {
    id: "STICKY_SIDEBAR",
    image: FORM_LAYOUT_URLS.STICKY_SIDEBAR,
    name: `${t("form.stickySidebar")}`,
    allowMinimize: false,
    minimizedTitle: "",
    isLeftAligned: true,
    isRightAligned: false,
    description: `${t("form.stickySidebarDescription")}`,
    hoverDescription: `${t("form.stickySidebarHoverDescription")}`,
  },
  {
    id: "POLITE_SLIDE_IN",
    image: FORM_LAYOUT_URLS.POLITE_SLIDE_IN,
    name: `${t("form.politeSlideIn")}`,
    allowMinimize: false,
    minimizedTitle: "",
    isLeftAligned: false,
    isRightAligned: true,
    description: `${t("form.politeSlideInDescription")}`,
    hoverDescription: `${t("form.politeSlideInHoverDescription")}`,
  },
  {
    id: "POPUP",
    image: FORM_LAYOUT_URLS.POPUP,
    name: `${t("form.popup")}`,
    description: `${t("form.popupDescription")}`,
    hoverDescription: `${t("form.politeSlideInHoverDescription")}`,
  },
  {
    id: "INLINE",
    image: FORM_LAYOUT_URLS.INLINE,
    name: `${t("form.inline")}`,
    description: `${t("form.inlineDescription")}`,
    hoverDescription: `${t("form.inlineHoverDescription")}`,
  },
];

export const TRIGGERS = [
  { id: "showOnScrolling", name: `${t("form.showOnScrolling")}`, showInput: true },
  { id: "showAfter", name: `${t("form.showAfter")}`, showInput: true },
  { id: "alwaysShow", name: `${t("form.alwaysShow")}`, showInput: false },
];

export const ACTIVATION_OPTIONS = [
  { id: "activateOnVisit", name: `${t("form.activateOn")}`, showInput: true },
  { id: "alwaysActivated", name: `${t("form.alwaysActivated")}`, showInput: false },
];

export const DEACTIVATION_OPTIONS = [
  {
    id: "deactivateAfter",
    name: `${t("form.deactivateAfterShowing")}`,
    showInput: true,
  },
  {
    id: "leadCollected",
    name: `${t("form.deactiveOnceLeadCollected")}`,
    showInput: false,
  },
  { id: "neverDeactivate", name: `${t("form.neverDeactivate")}`, showInput: false },
];

export const tooltipMessage = {
  stickyContact: `${t("common.stickyContactMessage")}`,
  backButton: `${t("survey.backButtonTooltipMessage")}`,
  disableAutoNavigation: `${t("survey.disableAutoNavigationTooltipMessage")}`,
  progressBar: `${t("survey.progressBarTooltipMessage")}`,
  disableAnimation: `${t("survey.disableAnimationTooltipMessage")}`,
  scrollToTop: `${t("survey.scrollToTopTooltipMessage")}`,
  fullScreenMode: `${t("form.fullScreenModetooltip")}`,
  enableTimezoneForm: t("form.enableTimezoneForm"),
  enableTimezoneSurvey: t("survey.enableTimezoneSurvey"),
};
export const DEFAULT_LABEL_COLOR = "188bf6";
export const DEFAULT_TEXT_COLOR = "000000";
export const PERCENTAGE_BAR_DEFAULT_TEXTCOLOR = "B2CCFF";
export const STEPS_DEFAULT_TEXTCOLOR = "00359E";
export const THEMES = [
  {
    theme: "steps",
    selectedImage: StepsSelectedImage,
    unselectedImage: StepsImage,
  },
  {
    theme: "percentageBar",
    selectedImage: PercentageBarSelectedImage,
    unselectedImage: PercentageBarImage,
  },
];
export const DEFAULT_FOOTER_SHADOW = "0 0 7px 0 rgba(50, 50, 50, 0.75)";
export const LEFT = "left";
export const RIGHT = "right";
export const LEFT_AND_RIGHT = "leftAndRight";
export const ONLY_ARROW = "onlyArrow";
export const ONLY_TEXT = "onlyText";
export const TEXT_AND_ARROW = "textAndArrow";

export const IS_FILLED = "isFilled";
export const IS_EMPTY = "isEmpty";

export const colorBgVariants = {
  success: "bg-success-100",
  primary: "bg-primary-100",
  error: "bg-error-100",
  warning: "bg-warning-100",
};

export const colorBgHoverVariants = {
  success: "hover:bg-success-50",
  primary: "hover:bg-primary-50",
  error: "hover:bg-error-50",
  warning: "hover:bg-warning-50",
};

export const colorTextVariants = {
  success: "text-success-600",
  primary: "text-primary-600",
  error: "text-error-600",
  warning: "text-warning-600",
};

export const MAX_HISTORY_LENGTH = 20;

export const dateFormat = ["MM-DD-YYYY", "DD-MM-YYYY", "YYYY-MM-DD"];

export const separators = ["-", "/", "."];

export const DEFAULT_ACTIVE_TAG_BG_COLOR = "009ef426";

export const BORDER_STYLES = [
  {
    name: t("common.borderTypeList.solid"),
    key: "solid",
    icon: SquareIcon,
  },
  {
    name: t("common.borderTypeList.dotted"),
    key: "dotted",
    icon: Maximize02Icon,
  },
  {
    name: t("common.borderTypeList.dashed"),
    key: "dashed",
    icon: () => h("img", { src: GridDotBlank }),
  },
  {
    name: t("common.borderTypeList.none"),
    key: "none",
    icon: SlashCircle01Icon,
  },
];

export const FB_EVENTS = [
  { label: "AddPaymentInfo", value: "AddPaymentInfo" },
  { label: "AddToCart", value: "AddToCart" },
  { label: "AddToWishlist", value: "AddToWishlist" },
  { label: "CompleteRegistration", value: "CompleteRegistration" },
  { label: "Contact", value: "Contact" },
  { label: "CustomizeProduct", value: "CustomizeProduct" },
  { label: "Donate", value: "Donate" },
  { label: "FindLocation", value: "FindLocation" },
  { label: "InitiateCheckout", value: "InitiateCheckout" },
  { label: "Lead", value: "Lead" },
  { label: "PageView", value: "PageView" },
  { label: "Purchase", value: "Purchase" },
  { label: "Schedule", value: "Schedule" },
  { label: "Search", value: "Search" },
  { label: "StartTrial", value: "StartTrial" },
  { label: "SubmitApplication", value: "SubmitApplication" },
  { label: "Subscribe", value: "Subscribe" },
  { label: "ViewContent", value: "ViewContent" },
  { label: "None", value: "None" },
];
export const FULL_WIDTH_FIELDS = [
  "img",
  "submit",
  "h1",
  "html",
  "captcha",
  "large_text",
  "source",
  "file_upload",
  "terms_and_conditions",
  "payment",
  "group", // google address
];

export const CONSTANTS = {
  ONE_COLUMN: 1,
  TWO_COLUMN: 2,
  SINGLE_LINE: 3,
};

export const couponAlignment = [
  { key: "left", name: t("common.payment.coupons.left") },
  { key: "right", name: t("common.payment.coupons.right") },
  { key: "fullWidth", name: t("common.payment.coupons.fullWidth") },
];

export const FOOTER_STYLE_SURVEY = {
  theme: "steps",
  backgroundFill: "006EEEFF",
  fontFamily: "Roboto",
  fontSize: 14,
  fontWeight: 400,
  height: 50,
  enableProgressBar: false,
  stickyFooter: true,
  progressBarStyle: {
    textColor: "B2CCFF",
    borderColor: "84ADFF",
    borderWidth: 1,
    borderRadius: 12,
    completeFillColor: "84ADFF",
    inactiveFillColor: "FFFFFF00",
  },
  buttonStyle: {
    buttonType: "textAndArrow",
    position: "leftAndRight",
    fontColor: "FFFFFF",
    nextButtonBgColor: "00000012",
    backButtonBgColor: "FFFFFF00",
    submitButtonBgColor: "00000012",
    prevBtnText: "PREV",
    nextBtnText: "NEXT",
    submitBtnText: "SUBMIT",
  },
  computedStyles: null,
};

export const FOOTER_STYLE_QUIZ = {
  theme: "steps",
  backgroundFill: "FFFFFF",
  fontFamily: "Roboto",
  fontSize: 14,
  fontWeight: 600,
  height: 50,
  enableProgressBar: false,
  stickyFooter: true,
  progressBarStyle: {
    textColor: "B2CCFF",
    borderColor: "84ADFF",
    borderWidth: 1,
    borderRadius: 12,
    completeFillColor: "84ADFF",
    inactiveFillColor: "FFFFFF00",
  },
  buttonStyle: {
    buttonType: "textAndArrow",
    position: "leftAndRight",
    fontColor: "000000",
    nextButtonBgColor: "FFFFFF00",
    backButtonBgColor: "FFFFFF00",
    submitButtonBgColor: "FFFFFF00",
    prevBtnText: "PREV",
    nextBtnText: "NEXT",
    submitBtnText: "SUBMIT",
  },
  computedStyles: null,
};

export const DUPLICATE_ALLOWED_FIELDS = ["h1", "img", "html"];

export const OPTIONS_FIELD = [
  "RADIO",
  "SINGLE_OPTIONS",
  "MULTIPLE_OPTIONS",
  "CHECKBOX",
  "SCORE",
  "TEXTBOX_LIST",
];
export const CATEGORY_FIELDS = ["RADIO", "SINGLE_OPTIONS", "MULTIPLE_OPTIONS", "CHECKBOX"];

export const JUMP_TO_FIELDS = ["RADIO", "SINGLE_OPTIONS", "MULTIPLE_OPTIONS", "CHECKBOX"];

export const PRODUCT_TYPE = {
  FORM: "form",
  QUIZ: "quiz",
  SURVEY: "survey",
};

export const LABEL_ALIGNMENT = [
  {
    name: t("common.left"),
    key: "left",
    icon: LeftArrow,
  },
  {
    name: t("common.top"),
    key: "top",
    icon: TopArrow,
  },
  {
    name: t("common.right"),
    key: "right",
    icon: RightArrow,
  },
];
