{
  "common": {
    "save": "Save",
    "saving": "Saving",
    "integrate": "Integrate",
    "preview": "Preview",
    "back": "Back",
    "alert": "Alert",
    "proceed": "Proceed",
    "cancel": "Cancel",
    "delete": "Delete",
    "embed": "Embed",
    "link": "Link",
    "iframeEmbed": "Iframe Embed",
    "styles": "Styles",
    "options": "Options",
    "quickAdd": "Quick Add",
    "customFields": "Custom Fields",
    "personalInfo": "Personal Info",
    "contactInfo": "Contact Info",
    "submit": "Submit",
    "address": "Address",
    "customized": "Customized",
    "layout": "Layout",
    "showLabel": "Show Label",
    "background": "Background",
    "border": "Border",
    "borderType": "Border Type",
    "cornerRadius": "Corner Radius",
    "width": "Width",
    "padding": "Padding",
    "top": "Top",
    "bottom": "Bottom",
    "left": "Left",
    "right": "Right",
    "shadow": "Shadow",
    "color": "Color",
    "horizontal": "Horizontal",
    "vertical": "Vertical",
    "blur": "Blur",
    "spread": "Spread",
    "fieldStyle": "Field Style",
    "fontColor": "Font Color",
    "label": "Label",
    "fontFamily": "Font Family",
    "size": "Size",
    "weight": "Weight",
    "placeholder": "Placeholder",
    "customCSS": "Custom CSS",
    "backgroundImage": "Background Image",
    "headerImage": "Header Image",
    "miscellaneous": "Miscellaneous",
    "agencyBranding": "Agency Branding",
    "imageUploadMessage": "Click or drag an image file to this area to upload",
    "backgroundImageTooltipMessage": "The background image is intended to cover the entire page, providing a visually appealing backdrop for the form. To view the background image, click on the Preview option.",
    "headerImageTooltipMessage": "The header image is designed to cover the entire width of the form and remains positioned at the top of the form. To view the header image, click on the Preview option.",
    "onSubmit": "On Submit",
    "facebookPixelID": "Facebook Pixel ID",
    "facebookPixelIDMessage": "Ignore this field if you plan to use this form/survey inside a funnel",
    "facebookPixelEvents": "Facebook Pixel Events",
    "onpageView": "On Page View",
    "stickyContact": "Sticky Contact",
    "stickyContactMessage": "Sticky contact will autofill contact information previously entered so you don't have to enter it twice.",
    "fieldDescription": "Field Description",
    "fieldTitle": "Field Title",
    "queryKey": "Query Key",
    "querykeyTooltipMessage": "The query key that can be used as a URL param to populate this field",
    "required": "Required",
    "hidden": "Hidden",
    "hiddenValue": "Hidden value",
    "enableCountryPicker": "Enable Country Picker",
    "altText": "Alt Text",
    "alignment": "Alignment",
    "imageWidth": "Image Width",
    "imageHeight": "Image Height",
    "logic": "Logic",
    "pleaseInput": "Please Input",
    "editHTML": "Edit HTML",
    "textStyle": "Text Style",
    "subText": "Sub Text",
    "text": "Text",
    "buttonStyle": "Button Style",
    "fullWidth": "Full Width",
    "htmlMessage": "The Custom HTML goes here",
    "htmlEditorHeader": "CUSTOM JAVASCRIPT / HTML",
    "htmlEditorBodyMessage": "Add your code here",
    "yesSave": "Yes, Save",
    "cancelKeep": "Cancel, Keep",
    "done": "Done",
    "addElement": "Add Element",
    "addUrlLike": "Add valid urls like",
    "doNotAddRelativePath": "Do not add relative paths like",
    "or": "or",
    "addCustomFields": "Add Custom Fields",
    "customFieldSearch": "Search by Name",
    "enterSourceValue": "Enter Source Value",
    "shortLabel": "Short Label",
    "customCSSTooltip": "Custom CSS takes precedence over form styling & themes and may have an impact on the theme's styling.",
    "fontFamilyTooltip": "Builder support all type of fonts if you can't see that in the list. Please search it in dropdown",
    "useGDPRCompliantFont": "Use GDPR Compliant Font",
    "enableTimezone": "Enable Timezone",
    "inputStyle": "Input Style",
    "conditionalLogicAlertOnSubmit": "Conditional logic will take precedence over the message / redirect here",
    "conditionalLogicAlertOnShowHide": "Conditional logic will take precedence over the hidden settings here",
    "placeholderFacebookPixelIdInput": "Enter Facebook Pixel ID",
    "doNotShowMessage": "Don't show again",
    "themes": {
      "themes": "Themes",
      "alertThemeMessage": "Switching themes will result in the loss of all the current style modifications.",
      "useTheme": "Use theme",
      "removeTheme": "Remove theme"
    },
    "box": "Box",
    "line": "Line",
    "message": "Message",
    "openUrl": "Open URL",
    "borderTypeList": {
      "dotted": "Dotted",
      "dashed": "Dashed",
      "solid": "Solid",
      "none": "None"
    },
    "button": "Button",
    "emailNotification": "Email Notification",
    "emailNotificationSubheader": "Receive an email when someone fills out your form",
    "subject": "Subject",
    "emailTo": "Email (To)",
    "senderName": "Sender name",
    "senderNameWarning": "Your (sub-account name) will be added if left blank",
    "emailError": "At least one email address is required",
    "emailTooltip": "The first email address is designated for the 'To' field. Any subsequent email addresses will be included in the 'Cc' field.",
    "clear": "Clear",
    "showNotificationsSettings": "Show Notifications Settings",
    "emailServicePaid": "This feature is a paid service and will incur charges based on your usage.",
    "integration": "Integration",
    "payment": {
      "payments": "Payments",
      "connected": "Connected",
      "connect": "Connect",
      "liveModeOn": "Live mode",
      "status": "Status",
      "test": "Test",
      "live": "Live",
      "paymentFields": "Payment Fields",
      "suggestAnAmount": "Suggest an amount",
      "selectCurrency": "Select Currency",
      "tagActiveBGColor": "Active Tag Color",
      "includeOtherAmount": "Include other amount",
      "footerInfo": "Footer Info",
      "paymentType": "Payment Type",
      "sellProducts": "Sell Products",
      "customAmount": "Custom Amount",
      "collectPayment": "Collect Payment",
      "generalSettings": "General fields",
      "product": {
        "addProduct": "Add Product",
        "productSettings": "Product Settings",
        "selectProduct": "Select Product",
        "editThisProduct": "To edit this product",
        "clickHere": "Click here",
        "show": "Show",
        "description": "Description",
        "image": "Image",
        "layoutSettings": "Layout settings",
        "singleColumn": "Single Column",
        "twoColumn": "Two Column",
        "threeColumn": "Three Column",
        "maxProductError": "Maximum 20 products are allowed in a form",
        "productSelectPlaceholder": "Please select a product",
        "orderConfirmation": "Order Confirmation",
        "orderConfirmationWarning": "A order confirmation page will be shown once payment is successful",
        "quantity": "Quantity",
        "createProduct": "To create a product"
      },
      "coupons": {
        "coupons": "Coupons",
        "enableCoupon": "Enable Coupon",
        "couponAlignment": "Coupon Alignment",
        "left": "Left",
        "right": "Right",
        "fullWidth": "Full Width",
        "tooltipMessage": "Set the position of coupon input from below types"
      }
    },
    "addressSettings": {
      "autoComplete": "Auto-Complete Address",
      "addressfields": "Address Fields",
      "placeholderSearch": "Search for an address",
      "autoCompleteTooltip": "Provides ability to search and automatically populate address. This feature is chargeable."
    },
    "autoResponder": "Auto Responder",
    "autoResponderSubheader": "Send an automated email to the person who submits the form (email includes a copy of the information they entered in the form)",
    "replyToEmail": "Reply-to-email",
    "open": "Open",
    "won": "Won",
    "lost": "Lost",
    "abandon": "Abandon",
    "selectAPipeline": "Select a pipeline",
    "stage": "Stage",
    "status": "Status",
    "opportunityValue": "Opportunity value",
    "createNewPipeline": "Create new pipeline",
    "editOpportunitySettings": "Edit Opportunity Settings",
    "contact": "Contact",
    "opportunity": "Opportunity",
    "add": "Add",
    "more": "More",
    "opportunityHeading": "Where do you want your opportunity to be updated?",
    "date": {
      "dateSettings": "Date Settings",
      "dateFormat": "Date Format",
      "dateSeparator": "Date Separator",
      "dateFormatTooltipMessage": "Select a date format. D stands for day, M for months, and Y for year",
      "dateSeparatorTooltipMessage": "Select a separator which will separate day, month, and year in format selected on Date Format",
      "disablePicker": "Disable Picker"
    },
    "restoreVersion": "Restore Version",
    "versionHistory": "Version History",
    "errorMessage": "Error, Retry again",
    "version": "Version:",
    "currentVersion": "Current Version",
    "messageStyling": "Message Styling",
    "validateEmailHeader": "Validate Email",
    "validateEmailDescription": "The button will become visible once the user enters their email. This feature is designed to help you generate quality leads.",
    "validatePhoneHeader": "Validate Phone",
    "validatePhoneDescription": "The button will become visible once the user enters their phone. This feature is designed to help you generate quality leads.",
    "validatePhoneWarning": "To enable SMS OTP verification, please ensure your phone number is set up in your location settings",
    "style": {
      "formLayoutType": "Form Type",
      "oneColumn": "One Column",
      "twoColumn": "Two Column",
      "singleLine": "Single Line",
      "fieldWidth": "Field Width",
      "labelAlignment": {
        "labelAlignment": "Label Alignment",
        "labelWidth": "Label Width"
      },
      "fieldSpacing": "Field Spacing",
      "color": "color",
      "fontColor": "Font color",
      "inputBackground": "Input Background",
      "layout": "Layout",
      "colorsAndBackground": "Colors & Background",
      "advanceSettings": "Advance Settings",
      "inputField": "Input Field",
      "borderWidth": "Border Width",
      "borderColor": "Border Color",
      "borderStyle": "Border Style",
      "fontSize": "Font Size",
      "fontWeight": "Font Weight",
      "labelColor": "Label Color",
      "placeholderColor": "Placeholder Color",
      "shortLabelColor": "Short Label Color"
    },
    "replyToTooltip": "Customize the default Reply-To email address by using {'{'}{'{'}contact.email{'}'}{'}'} to enable replies to the contact who submits the form.",
    "content": "Content",
    "calculations": "Calculations",
    "uploadOptions": "Upload Options",
    "opportunitySettingsWarning": "Please set up opportunity settings by clicking on the Edit Opportunity Settings button.",
    "question": "Question",
    "createContactOnPartialSubmission": "Create Contact on Partial Submission",
    "howItWorks": "How it works?",
    "createContactOnPartialSubmissionDescription": "Finish the slide and click \"Next\". A new contact will automatically created and stored.",
    "swapWarningMessageSurvey": "Editing or swapping options will impact this custom field across all places where the field is used. It can also reset conditional logic. ",
    "swapWarningMessageForm": "Editing or swapping options will impact this custom field across all places where the field is used. ",
    "optionLabel": "Option Label",
    "calculationValue": "Calculation Value",
    "addOption": "Add Option",
    "enableCalculations": "Enable Calculations",
    "productSelectionSubtext": "Allow single or multiple product selection",
    "multi": "Multi",
    "single": "Single",
    "productSelectionHeading": "Product Selection"
  },
  "survey": {
    "typeOfBuilder": "Survey",
    "survyeStyle": "Survey Style",
    "surveyName": "Survey Name",
    "surveyElement": "Survey Element",
    "addSurveyElement": "Add Survey Element",
    "unsavedAlertMessage": "The survey has unsaved work. Are you sure you want to leave and discard all unsaved work?",
    "thirdPartyScriptAlertMessage": "Third-party scripts inside the HTML block can provide powerful functionality, but they also bring risks to privacy, security, performance and page behavior. Therefore, we recommend that you to review the contents of this script before saving it to the survey.",
    "integrateHeader": "Integrate Survey",
    "integrateSubHeader": "Use the following options to integrate your custom survey into your website",
    "integrateCopyLinkMessage": "Copy the link below and easily share it anywhere you wish.",
    "onSurveySubmission": "On Survey Submission",
    "surveySettings": "Survey Settings",
    "oneQuestionAtTime": "One question at a time",
    "backButton": "Back Button",
    "backButtonTooltipMessage": "Back button will help user to move the previous slide",
    "disableAutoNavigation": "Disable Auto Navigation",
    "disableAutoNavigationTooltipMessage": "If turned on, The survey will not automatically go to the next slide",
    "progressBar": "Progress Bar",
    "progressBarTooltipMessage": "Turning this Off will remove the Progress Bar from the survey",
    "disableAnimation": "Disable Animation",
    "disableAnimationTooltipMessage": "Turning this on will disable the slide transition animation",
    "scrollToTop": "Scroll to Top",
    "scrollToTopTooltipMessage": "If turned on, the survey will scroll to top on the next slide",
    "footerHTML": "Footer HTML",
    "footerHTMLMessage": "You can write custom CSS (using style tag) and HTML inside the same box",
    "editFooter": "Edit Footer",
    "disqualifyImmediately": "Disqualify Immediately",
    "disqualifyAfterSubmit": "Disqualify After Submit",
    "slideConflictAlertMessage": "This slide contains multiple questions that have a logic set to them. This can cause some unintended behavior",
    "addSlide": "Add Slide",
    "settings": "Settings",
    "delete": "Delete",
    "slideDeleteAlertMessage": "Are you sure you want to delete this slide?",
    "slideDeleteBetweenAlertMessage": "This will rearrange the other slides, are you sure you want to delete this slide?",
    "slideName": "Slide Name",
    "slidePosition": "Slide Position",
    "jumpTo": "Jump To",
    "enableTimezoneSurvey": "Activating this option will capture the time zone information of the individual submitting the survey",
    "footer": {
      "footer": "Footer",
      "enableNewFooter": "Enable New Footer",
      "stickToSurvey": " Stick to Survey",
      "backgroundFill": "Background Fill",
      "footerHeight": "Footer Height",
      "enableProgressBar": "Enable Progress Bar",
      "buttons": "Buttons",
      "buttonType": "Button Type",
      "fillNext": "Fill (next)",
      "fillPrevious": "Fill (previous)",
      "fillSubmit": "Fill (Submit)",
      "textColor": "Button Text",
      "position": "Position",
      "previousText": "Previous (text)",
      "nextText": "Next (text)",
      "submitText": "Submit (text)",
      "fillComplete": "Fill Complete",
      "fillInactive": "Fill Inactive",
      "footerType": "Footer Type",
      "stickToCard": "Stick to card",
      "stickToPage": "Stick to page"
    },
    "surveyNotfication": "Survey notification",
    "style": {
      "surveyPadding": "Survey Padding"
    },
    "autoResponderSubheader": "Send an automated email to the person who submits the survey (email includes a copy of the information they entered in the survey)"
  },
  "form": {
    "typeOfBuilder": "Form",
    "formStyle": "Form Style",
    "formElement": "Form Element",
    "addFormElement": "Add Form Element",
    "addConditionalLogic": "Add Conditional Logic",
    "inlineForm": "Inline Form",
    "formName": "Form Name",
    "fullScreenMode": "Full Screen Mode",
    "fullScreenModetooltip": "Switching it on will cause the form to expand across the entire width of its container, stretching to the edge of the embedding area while adhering to the form's width.",
    "enableTimezoneForm": "Activating this option will capture the time zone information of the individual submitting the form",
    "formSettings": "Form Settings",
    "unsavedAlertMessage": "The form has unsaved work. Are you sure you want to leave and discard all unsaved work?",
    "thirdPartyScriptAlertMessage": "Third-party scripts inside the HTML block can provide powerful functionality, but they also bring risks to privacy, security, performance and page behavior. Therefore, we recommend that you to review the contents of this script before saving it to the form.",
    "onFormSubmission": "On Form Submission",
    "integrateForm": "Integrate Form",
    "embedlayoutType": "Embed layout type",
    "embedlayoutTypeSubMessage": "Select a layout to embed in a funnel or an external website",
    "triggerType": "Trigger type",
    "showOnScrolling": "Show on scrolling",
    "showAfter": "Show after",
    "seconds": "seconds",
    "alwaysShow": "Always show",
    "activationOptions": "Activation options",
    "activateOn": "Activate on",
    "visit": "visit",
    "alwaysActivated": "Always activated",
    "deactivationOptions": "Deactivation options",
    "deactivateAfterShowing": "Deactivate after showing",
    "times": "times",
    "deactiveOnceLeadCollected": "Deactivate once lead is collected",
    "neverDeactivate": "Never deactivate",
    "copyEmbedCode": "Copy embed code",
    "copyFormLink": "Copy form link",
    "openFormLink": "Open form link",
    "inline": "Inline",
    "inlineDescription": "An inline form appears along with the content of the page as a native element. It does not overlay and cannot be closed or minimized.",
    "inlineHoverDescription": "An inline form appears along with the content of the page as a native element. It does not overlay and cannot be closed or minimized.",
    "popup": "Popup",
    "popupDescription": "A pop-up shows form as an overlay inside a light-box. It cannot be minimized but closed.",
    "popupHoverDescription": "A pop-up shows form as an overlay inside a light-box. It cannot be minimized but closed.",
    "politeSlideIn": "Polite slide-in",
    "politeSlideInDescription": "A polite slide-in appears on the bottom side of screen and disappears once closed",
    "politeSlideInHoverDescription": "Appears on the bottom side of screen and disappears once closed",
    "stickySidebar": "Sticky sidebar",
    "stickySidebarDescription": "A Sticky sidebar will appear on the side of the screen and can be minimized to a tab that sticks to the side of the window",
    "stickySidebarHoverDescription": "Appear on the side of the screen and can be minimized to a tab that sticks to the side of the window",
    "allowMinimize": "Allow minimize",
    "conditonalLogic": {
      "conditions": "Conditions",
      "noExistingCondition": "No existing conditions yet",
      "freeRoamMessage": "You're free to roam without the rules - at least for now",
      "addNewCondition": "Add New Condition",
      "redirectToUrl": "Redirect to URL",
      "displayCustomMessage": "Display custom message",
      "disqualifyLead": "Disqualify lead",
      "showHideFields": "Show/Hide Fields",
      "openBelowUrl": "Open below URL",
      "showCustomMessage": "Show custom message",
      "alertConditionDelete": "Are you sure you want to delete this condition?",
      "deleteCondition": "Delete Condition",
      "conditionExecutedMessage": "Conditions will be executed from top-down order.",
      "allOperationOptions": {
        "isEqualTo": "Is Equal To",
        "isNotEqualTo": "Is Not Equal To",
        "contains": "Contains",
        "startsWith": "Starts With",
        "endsWith": "Ends With",
        "isEmpty": "Is Empty",
        "isFilled": "Is Filled",
        "before": "Before",
        "after": "After",
        "greaterThan": "Greater Than",
        "lessThan": "Less Than"
      },
      "logic": {
        "if": "If",
        "then": "Then",
        "and": "And",
        "or": "Or"
      },
      "phoneAlertMessage": "To search for phone numbers with a country code, you need to explicitly include the '+' symbol in your search query.",
      "deleteAlertMessage": "Are you sure you want to delete this field? The condition associated with this field will also be removed.",
      "deleteField": "Delete Field"
    },
    "minimizedTitlePlaceholder": "Minimized title",
    "dragYourElementFromLeft": "Drag your elements here from the left",
    "formNotification": "Form notification",
    "style": {
      "formPadding": "Form Padding"
    }
  },
  "quiz": {
    "quizElement": "Quiz Element",
    "quizNotification": "Quiz notification",
    "viewAndCustomise": "View And Customise",
    "categoryAndScore": "Category and Score",
    "provideFeedback": "Provide feedback",
    "addQuizElement": "Add Quiz Element",
    "typeOfBuilder": "Quiz",
    "catgory": {
      "manageCategories": "Manage Categories"
    },
    "quizSettings": "Quiz Settings",
    "onQuizSubmission": "On Quiz Submission",
    "settings": "Settings",
    "deletePage": "Delete Page",
    "quizResult": {
      "resultTemplate": "Result Template",
      "quizResultAlertMessage": "Chosen result page will be shown once quiz is completed"
    },
    "autoResponderSubheader": "Send an automated email to the person who submits the quiz (email includes a copy of the information they entered in the quiz)",
    "integrateHeader": "Integrate Quiz",
    "integrateSubHeader": "Use the following options to integrate your custom quiz into your website",
    "integrateCopyLinkMessage": "Copy the link below and easily share it anywhere you wish.",
    "pageName": "Page Name",
    "pagePosition": "Page Position"
  }
}
