{
  "common": {
    "addCustomFields": "Aangepaste velden toevoegen",
    "addElement": "Element toevoegen",
    "addUrlLike": "Voeg geldige URL's toe, zoals",
    "address": "Adres",
    "agencyBranding": "Branding van bureaus",
    "alert": "Waarschuw",
    "alignment": "Uitlijning",
    "altText": "Alt-tekst",
    "back": "Rug",
    "background": "Achtergrond",
    "backgroundImage": "Achtergrond afbeelding",
    "backgroundImageTooltipMessage": "De achtergrondafbeelding is bedoeld om de hele pagina te bedekken en biedt een visueel aantrekkelijke achtergrond voor het formulier. \nOm de achtergrondafbeelding te bekijken, klikt u op de optie Voorbeeld.",
    "blur": "Vervagen",
    "border": "Grens",
    "borderType": "Randtype",
    "bottom": "Onderkant",
    "buttonStyle": "Knopstijl",
    "cancel": "Annuleren",
    "cancelKeep": "Annuleren, behouden",
    "color": "Kleur",
    "conditionalLogicAlertOnSubmit": "Voorwaardelijke logica heeft hier voorrang op het bericht/de omleiding",
    "contactInfo": "Contact informatie",
    "cornerRadius": "Hoekradius",
    "customCSS": "Aangepaste CSS",
    "customCSSTooltip": "Aangepaste CSS heeft voorrang op formulierstijl",
    "customFieldSearch": "Zoek op naam",
    "customFields": "Aangepaste velden",
    "customized": "Aangepast",
    "delete": "Verwijderen",
    "doNotAddRelativePath": "Voeg geen relatieve paden toe, zoals",
    "doNotShowMessage": "Niet meer laten zien",
    "done": "Klaar",
    "editHTML": "HTML bewerken",
    "embed": "Integreren",
    "enableCountryPicker": "Landkiezer inschakelen",
    "enterSourceValue": "Voer de bronwaarde in",
    "facebookPixelEvents": "Facebook Pixel-evenementen",
    "facebookPixelID": "Facebook Pixel-ID",
    "facebookPixelIDMessage": "Negeer dit veld als u van plan bent dit formulier/enquête in een trechter te gebruiken",
    "fieldDescription": "Veldbeschrijving",
    "fieldStyle": "Veldstijl",
    "fieldTitle": "Veldtitel",
    "fontColor": "Lettertypekleur",
    "fontFamily": "Lettertypefamilie",
    "fontFamilyTooltip": "Builder ondersteunt alle soorten lettertypen als u dat niet in de lijst kunt zien. \nZoek het op in de vervolgkeuzelijst",
    "fullWidth": "Volle breedte",
    "headerImage": "Kopafbeelding",
    "headerImageTooltipMessage": "De headerafbeelding is ontworpen om de gehele breedte van het formulier te bestrijken en blijft bovenaan het formulier geplaatst. \nOm de headerafbeelding te bekijken, klikt u op de optie Voorbeeld.",
    "hidden": "Verborgen",
    "hiddenValue": "Verborgen waarde",
    "horizontal": "Horizontaal",
    "htmlEditorBodyMessage": "Voeg hier uw code toe",
    "htmlEditorHeader": "AANGEPASTE JAVASCRIPT / HTML",
    "htmlMessage": "De aangepaste HTML komt hier",
    "iframeEmbed": "Ifrmae Embed",
    "imageHeight": "Afbeeldingshoogte",
    "imageUploadMessage": "Klik of sleep een afbeeldingsbestand naar dit gebied om te uploaden",
    "imageWidth": "Afbeeldingsbreedte",
    "inputStyle": "Invoerstijl",
    "integrate": "Integreren",
    "label": "Etiket",
    "layout": "Indeling",
    "left": "Links",
    "link": "Koppeling",
    "logic": "Logica",
    "miscellaneous": "Gemengd",
    "onSubmit": "Bij verzenden",
    "onpageView": "Op paginaweergave",
    "options": "Opties",
    "or": "of",
    "padding": "Opvulling",
    "personalInfo": "Persoonlijke informatie",
    "placeholder": "Tijdelijke aanduiding",
    "placeholderFacebookPixelIdInput": "Voer Facebook Pixel-ID in",
    "pleaseInput": "Vul alsjeblieft in",
    "preview": "Voorbeeld",
    "proceed": "Doorgaan",
    "queryKey": "Querysleutel",
    "querykeyTooltipMessage": "De querysleutel die kan worden gebruikt als URL-parameter om dit veld in te vullen",
    "quickAdd": "Snel toevoegen",
    "required": "Vereist",
    "right": "Rechts",
    "save": "Redden",
    "saving": "Besparing",
    "shadow": "Schaduw",
    "shortLabel": "Kort etiket",
    "showLabel": "Etiket tonen",
    "size": "Maat",
    "spread": "Spreiding",
    "stickyContact": "Kleverig contact",
    "stickyContactMessage": "Sticky contact vult eerder ingevoerde contactgegevens automatisch aan, zodat u deze niet twee keer hoeft in te voeren.",
    "styles": "Stijlen",
    "subText": "Subtekst",
    "submit": "Indienen",
    "text": "Tekst",
    "textStyle": "Tekststijl",
    "themes": {
      "alertThemeMessage": "Als u van thema verandert, gaan alle huidige stijlwijzigingen verloren.",
      "removeTheme": "Thema verwijderen",
      "themes": "Thema's",
      "useTheme": "Gebruik thema"
    },
    "top": "Bovenkant",
    "useGDPRCompliantFont": "Gebruik een AVG-compatibel lettertype",
    "vertical": "Verticaal",
    "weight": "Gewicht",
    "width": "Breedte",
    "yesSave": "Ja, opslaan",
    "box": "Doos",
    "line": "Lijn",
    "message": "Bericht",
    "openUrl": "Open url",
    "borderTypeList": {
      "dotted": "Gestippeld",
      "none": "Geen",
      "solid": "Stevig",
      "dashed": "Gestormd"
    },
    "button": "Knop",
    "enableTimezone": "Tijdzone inschakelen",
    "emailNotification": "E-mail notificatie",
    "emailNotificationSubheader": "Ontvang een e-mail wanneer iemand uw formulier invult",
    "subject": "Onderwerp",
    "emailTo": "Email naar)",
    "senderName": "Naam afzender",
    "senderNameWarning": "Uw (subaccountnaam) wordt toegevoegd als u dit leeg laat",
    "emailTooltip": "Het eerste e-mailadres wordt toegewezen aan het veld 'Aan'. \nEventuele volgende e-mailadressen worden opgenomen in het veld 'Cc'.",
    "emailError": "Er is minimaal één e-mailadres vereist",
    "clear": "Duidelijk",
    "showNotificationsSettings": "Toon meldingeninstellingen",
    "emailServicePaid": "Deze functie is een betaalde service en er worden kosten in rekening gebracht op basis van uw gebruik.",
    "integration": "Integratie",
    "payment": {
      "connect": "Aansluiten",
      "connected": "Verbonden",
      "footerInfo": "Voettekstinformatie",
      "includeOtherAmount": "Neem een ​​ander bedrag op",
      "live": "Live",
      "paymentFields": "Betalingsvelden",
      "selectCurrency": "selecteer valuta",
      "status": "Toestand",
      "suggestAnAmount": "Stel een bedrag voor",
      "test": "Test",
      "liveModeOn": "Live-modus",
      "customAmount": "Aangepast bedrag",
      "paymentType": "Betalingswijze",
      "product": {
        "addProduct": "Product toevoegen",
        "clickHere": "Klik hier",
        "description": "Beschrijving",
        "editThisProduct": "Om dit product te bewerken",
        "image": "Afbeelding",
        "layoutSettings": "Opmaak instellingen",
        "maxProductError": "Er zijn maximaal 20 producten toegestaan ​​in een formulier",
        "productSelectPlaceholder": "Selecteer een product",
        "productSettings": "Productinstellingen",
        "selectProduct": "Selecteer product",
        "show": "Show",
        "singleColumn": "Enkele kolom",
        "threeColumn": "Drie kolommen",
        "twoColumn": "Twee kolommen",
        "orderConfirmationWarning": "Zodra de betaling succesvol is, wordt er een bestelbevestigingspagina weergegeven",
        "orderConfirmation": "Order bevestiging",
        "createProduct": "Om een ​​product te maken",
        "quantity": "Hoeveelheid"
      },
      "sellProducts": "Producten verkopen",
      "generalSettings": "Algemene velden",
      "tagActiveBGColor": "Actieve tagkleur",
      "collectPayment": "Incasso",
      "payments": "Betalingen",
      "coupons": {
        "couponAlignment": "Couponuitlijning",
        "coupons": "Coupons",
        "enableCoupon": "Coupon inschakelen",
        "fullWidth": "Volle breedte",
        "left": "Links",
        "right": "Rechts",
        "tooltipMessage": "Stel de positie van de couponinvoer in van de onderstaande typen"
      }
    },
    "autoResponder": "Automatische beantwoorder",
    "autoResponderSubheader": "Stuur een geautomatiseerde e-mail naar de persoon die het formulier indient (de e-mail bevat een kopie van de informatie die hij of zij in het formulier heeft ingevoerd)",
    "replyToEmail": "Antwoord op e-mail",
    "open": "Open",
    "won": "Won",
    "lost": "Kwijt",
    "abandon": "Verlaten",
    "selectAPipeline": "Selecteer een pijplijn",
    "stage": "Fase",
    "status": "Toestand",
    "opportunityValue": "Kanswaarde",
    "createNewPipeline": "Maak een nieuwe pijplijn",
    "editOpportunitySettings": "Opportuniteitsinstellingen bewerken",
    "contact": "Contact",
    "opportunity": "Mogelijkheid",
    "add": "Toevoegen",
    "more": "Meer",
    "opportunityHeading": "Waar wilt u dat uw mogelijkheid wordt bijgewerkt?",
    "date": {
      "dateFormat": "Datumnotatie",
      "dateFormatTooltipMessage": "Selecteer een datumformaat. \nD staat voor dag, M voor maanden en Y voor jaar",
      "dateSeparator": "Datumscheidingsteken",
      "dateSeparatorTooltipMessage": "Selecteer een scheidingsteken dat de dag, de maand en het jaar scheidt in het formaat dat is geselecteerd bij Datumformaat",
      "dateSettings": "Datuminstellingen",
      "disablePicker": "Schakel Kiezer uit"
    },
    "restoreVersion": "Versie herstellen",
    "versionHistory": "Versiegeschiedenis",
    "errorMessage": "Fout. Probeer het opnieuw",
    "version": "Versie:",
    "currentVersion": "Huidige versie",
    "conditionalLogicAlertOnShowHide": "Voorwaardelijke logica heeft hier voorrang op de verborgen instellingen",
    "validateEmailHeader": "Email valideren",
    "validateEmailDescription": "De knop wordt zichtbaar zodra de gebruiker zijn e-mailadres invoert. \nDeze functie is ontworpen om u te helpen kwaliteitsleads te genereren.",
    "validatePhoneHeader": "Valideer telefoon",
    "validatePhoneDescription": "De knop wordt zichtbaar zodra de gebruiker zijn telefoon invoert. \nDeze functie is ontworpen om u te helpen kwaliteitsleads te genereren.",
    "validatePhoneWarning": "Om sms-OTP-verificatie in te schakelen, moet u ervoor zorgen dat uw telefoonnummer is ingesteld in uw locatie-instellingen",
    "style": {
      "formLayoutType": "Formuliertype",
      "oneColumn": "Eén kolom",
      "singleLine": "Enkele lijn",
      "twoColumn": "Twee kolommen",
      "fieldWidth": "Veldbreedte",
      "fieldSpacing": "Veldafstand",
      "advanceSettings": "Geavanceerde instellingen",
      "borderColor": "Rand kleur",
      "borderStyle": "Randstijl",
      "borderWidth": "Grensbreedte",
      "color": "kleur",
      "colorsAndBackground": "Kleuren",
      "fontColor": "Lettertype kleur",
      "fontSize": "Lettertypegrootte",
      "fontWeight": "Lettertype dikte",
      "inputBackground": "Achtergrond invoeren",
      "inputField": "Invoer veld",
      "labelColor": "Etiketkleur",
      "layout": "Indeling",
      "placeholderColor": "Tijdelijke aanduiding kleur",
      "shortLabelColor": "Korte labelkleur",
      "labelAlignment": {
        "labelAlignment": "Etiketuitlijning",
        "labelWidth": "Etiketbreedte"
      }
    },
    "replyToTooltip": "Pas het standaard antwoord-e-mailadres aan door {'{'}{'{'}contact.email{'}'}{'}'} te gebruiken om antwoorden in te schakelen naar de contactpersoon die het formulier verzendt.",
    "content": "Inhoud",
    "calculations": "Berekeningen",
    "uploadOptions": "Uploadopties",
    "messageStyling": "Berichtstijl",
    "opportunitySettingsWarning": "Stel de opportunityinstellingen in door op de knop Opportunity-instellingen bewerken te klikken.",
    "createContactOnPartialSubmission": "Contactpersoon maken voor gedeeltelijke indiening",
    "howItWorks": "Hoe het werkt?",
    "createContactOnPartialSubmissionDescription": "Beëindig de dia en klik op \"Volgende\". \nEr wordt automatisch een nieuw contact aangemaakt en opgeslagen.",
    "swapWarningMessageSurvey": "Het bewerken of verwisselen van opties heeft invloed op dit aangepaste veld op alle plaatsen waar het veld wordt gebruikt. \nHet kan ook de voorwaardelijke logica resetten.",
    "swapWarningMessageForm": "Het bewerken of verwisselen van opties heeft invloed op dit aangepaste veld op alle plaatsen waar het veld wordt gebruikt.",
    "addOption": "Optie toevoegen",
    "calculationValue": "Berekeningswaarde",
    "enableCalculations": "Schakel berekeningen in",
    "optionLabel": "Optielabel",
    "addressSettings": {
      "addressfields": "Adresvelden",
      "autoComplete": "Automatisch aanvullen adres",
      "autoCompleteTooltip": "Biedt de mogelijkheid om adressen te zoeken en automatisch in te vullen. \nVoor deze functie zijn kosten in rekening gebracht.",
      "placeholderSearch": "Zoek een adres"
    },
    "productSelectionSubtext": "Sta selectie van enkele of meerdere producten toe",
    "multi": "Multi",
    "single": "Enkel",
    "productSelectionHeading": "Productselectie"
  },
  "form": {
    "activateOn": "Activeer aan",
    "activationOptions": "Activeringsopties",
    "addConditionalLogic": "Voeg voorwaardelijke logica toe",
    "addFormElement": "Formulierelement toevoegen",
    "allowMinimize": "Minimaliseren toestaan",
    "alwaysActivated": "Altijd geactiveerd",
    "alwaysShow": "Altijd laten zien",
    "conditonalLogic": {
      "addNewCondition": "Nieuwe voorwaarde toevoegen",
      "alertConditionDelete": "Weet u zeker dat u deze voorwaarde wilt verwijderen?",
      "conditionExecutedMessage": "Voorwaarden worden van bovenaf uitgevoerd.",
      "conditions": "Voorwaarden",
      "deleteCondition": "Voorwaarde verwijderen",
      "displayCustomMessage": "Aangepast bericht weergeven",
      "disqualifyLead": "Lead diskwalificeren",
      "freeRoamMessage": "Je bent vrij om rond te dwalen zonder de regels - tenminste voorlopig",
      "noExistingCondition": "Er zijn nog geen bestaande voorwaarden",
      "openBelowUrl": "Open onderstaande URL",
      "redirectToUrl": "Omleiden naar URL",
      "showCustomMessage": "Aangepast bericht weergeven",
      "showHideFields": "Velden tonen/verbergen",
      "allOperationOptions": {
        "after": "Na",
        "before": "Voor",
        "contains": "Bevat",
        "endsWith": "Eindigt met",
        "greaterThan": "Groter dan",
        "isEmpty": "Is leeg",
        "isEqualTo": "Is gelijk aan",
        "isFilled": "Is gevuld",
        "isNotEqualTo": "Is niet gelijk aan",
        "lessThan": "Minder dan",
        "startsWith": "Begint met"
      },
      "logic": {
        "and": "En",
        "if": "Als",
        "or": "Of",
        "then": "Dan"
      },
      "phoneAlertMessage": "Om telefoonnummers met een landcode te zoeken, moet u expliciet het '+'-symbool in uw zoekopdracht opnemen.",
      "deleteAlertMessage": "Weet u zeker dat u dit veld wilt verwijderen? \nDe voorwaarde die aan dit veld is gekoppeld, wordt ook verwijderd.",
      "deleteField": "Veld verwijderen"
    },
    "minimizedTitlePlaceholder": "Geminimaliseerde titel",
    "copyEmbedCode": "Kopieer de embed code",
    "deactivateAfterShowing": "Deactiveer na weergave",
    "deactivationOptions": "Deactiveringsopties",
    "deactiveOnceLeadCollected": "Deactiveer zodra de lead is verzameld",
    "embedlayoutType": "Lay-outtype insluiten",
    "embedlayoutTypeSubMessage": "Selecteer een lay-out om in een trechter of een externe website in te sluiten",
    "formElement": "Vormelement",
    "formName": "Formulier naam",
    "formSettings": "Formulierinstellingen",
    "formStyle": "Vormstijl",
    "fullScreenMode": "Volledig schermmodus",
    "fullScreenModetooltip": "Als u dit inschakelt, wordt het formulier over de gehele breedte van de container uitgebreid, tot aan de rand van het insluitgebied, terwijl het zich aan de breedte van het formulier houdt.",
    "inline": "In lijn",
    "inlineDescription": "Er verschijnt een inlineformulier samen met de inhoud van de pagina als een native element. \nHet overlapt niet en kan niet worden gesloten of geminimaliseerd.",
    "inlineForm": "Inline-formulier",
    "inlineHoverDescription": "Er verschijnt een inlineformulier samen met de inhoud van de pagina als een native element. \nHet overlapt niet en kan niet worden gesloten of geminimaliseerd.",
    "integrateForm": "Formulier integreren",
    "neverDeactivate": "Nooit deactiveren",
    "onFormSubmission": "Bij het indienen van formulieren",
    "politeSlideIn": "Beleefd instappen",
    "politeSlideInDescription": "Er verschijnt een beleefde slide-in aan de onderkant van het scherm en verdwijnt zodra deze is gesloten",
    "politeSlideInHoverDescription": "Verschijnt aan de onderkant van het scherm en verdwijnt zodra het gesloten is",
    "popup": "Pop-up",
    "popupDescription": "Een pop-up toont de vorm als een overlay in een lichtbak. \nHet kan niet worden geminimaliseerd, maar gesloten.",
    "popupHoverDescription": "Een pop-up toont de vorm als een overlay in een lichtbak. \nHet kan niet worden geminimaliseerd, maar gesloten.",
    "seconds": "seconden",
    "showAfter": "Toon na",
    "showOnScrolling": "Toon bij scrollen",
    "stickySidebar": "Kleverige zijbalk",
    "stickySidebarDescription": "Er verschijnt een plakkerige zijbalk aan de zijkant van het scherm, die kan worden geminimaliseerd tot een tabblad dat aan de zijkant van het venster blijft plakken",
    "stickySidebarHoverDescription": "Verschijnen aan de zijkant van het scherm en kunnen worden geminimaliseerd tot een tabblad dat aan de zijkant van het venster blijft plakken",
    "thirdPartyScriptAlertMessage": "Scripts van derden binnen het HTML-blok kunnen krachtige functionaliteit bieden, maar brengen ook risico's met zich mee voor de privacy, beveiliging, prestaties en paginagedrag. \nDaarom raden we u aan de inhoud van dit script te bekijken voordat u het in het formulier opslaat.",
    "times": "keer",
    "triggerType": "Triggertype",
    "typeOfBuilder": "Formulier",
    "visit": "bezoek",
    "dragYourElementFromLeft": "Sleep je elementen vanaf de linkerkant hierheen",
    "enableTimezoneForm": "Als u deze optie activeert, wordt de tijdzone-informatie vastgelegd van de persoon die het formulier indient",
    "formNotification": "Formuliermelding",
    "copyFormLink": "Formulierlink kopiëren",
    "openFormLink": "Formulierlink openen",
    "unsavedAlertMessage": "Het formulier bevat niet-opgeslagen werk. \nWeet u zeker dat u wilt vertrekken en al het niet-opgeslagen werk wilt verwijderen?",
    "style": {
      "formPadding": "Vormvulling"
    }
  },
  "survey": {
    "addSlide": "Dia toevoegen",
    "addSurveyElement": "Enquête-element toevoegen",
    "backButton": "Terug knop",
    "backButtonTooltipMessage": "De Terug-knop helpt de gebruiker de vorige dia te verplaatsen",
    "delete": "Verwijderen",
    "disableAnimation": "Animatie uitschakelen",
    "disableAnimationTooltipMessage": "Als u dit inschakelt, wordt de dia-overgangsanimatie uitgeschakeld",
    "disableAutoNavigation": "Schakel automatische navigatie uit",
    "disableAutoNavigationTooltipMessage": "Indien ingeschakeld, gaat de enquête niet automatisch naar de volgende dia",
    "disqualifyAfterSubmit": "Diskwalificeren na indiening",
    "disqualifyImmediately": "Onmiddellijk diskwalificeren",
    "editFooter": "Voettekst bewerken",
    "footer": {
      "backgroundFill": "Achtergrondvulling",
      "buttonType": "Knoptype",
      "buttons": "Toetsen",
      "enableNewFooter": "Schakel Nieuwe voettekst in",
      "enableProgressBar": "Voortgangsbalk inschakelen",
      "fillComplete": "Vul voltooid",
      "fillInactive": "Vul Inactief",
      "fillNext": "Vul (volgende)",
      "fillPrevious": "Vul (vorige)",
      "fillSubmit": "Invullen (verzenden)",
      "footer": "Voettekst",
      "footerHeight": "Voettekst Hoogte",
      "nextText": "Volgende (tekst)",
      "position": "Positie",
      "previousText": "Vorige (tekst)",
      "stickToSurvey": "Blijf bij enquête",
      "submitText": "Verzenden (tekst)",
      "textColor": "Tekst kleur",
      "footerType": "Voetteksttype",
      "stickToCard": "Blijf bij de kaart",
      "stickToPage": "Blijf bij pagina"
    },
    "footerHTML": "HTML-voettekst",
    "footerHTMLMessage": "U kunt aangepaste CSS (met behulp van de stijltag) en HTML in hetzelfde vak schrijven",
    "integrateCopyLinkMessage": "Kopieer de onderstaande link en deel deze eenvoudig waar u maar wilt.",
    "integrateHeader": "Enquête integreren",
    "integrateSubHeader": "Gebruik de volgende opties om uw aangepaste enquête in uw website te integreren",
    "jumpTo": "Spring naar",
    "onSurveySubmission": "Over het indienen van enquêtes",
    "oneQuestionAtTime": "Eén vraag tegelijk",
    "progressBar": "Voortgangsbalk",
    "progressBarTooltipMessage": "Als u dit uitschakelt, wordt de voortgangsbalk uit de enquête verwijderd",
    "scrollToTop": "Scroll naar boven",
    "scrollToTopTooltipMessage": "Indien ingeschakeld, scrollt de enquête naar boven op de volgende dia",
    "settings": "Instellingen",
    "slideConflictAlertMessage": "Deze dia bevat meerdere vragen waarvoor een logica is ingesteld. \nDit kan onbedoeld gedrag veroorzaken",
    "slideDeleteAlertMessage": "Weet u zeker dat u deze dia wilt verwijderen?",
    "slideDeleteBetweenAlertMessage": "Hierdoor worden de andere dia's opnieuw gerangschikt. Weet u zeker dat u deze dia wilt verwijderen?",
    "slideName": "Dianaam",
    "slidePosition": "Schuif positie",
    "surveyElement": "Enquête-element",
    "surveyName": "Enquêtenaam",
    "surveySettings": "Enquête-instellingen",
    "survyeStyle": "Enquêtestijl",
    "thirdPartyScriptAlertMessage": "Scripts van derden binnen het HTML-blok kunnen krachtige functionaliteit bieden, maar brengen ook risico's met zich mee voor de privacy, beveiliging, prestaties en paginagedrag. \nDaarom raden we u aan de inhoud van dit script te bekijken voordat u het in de enquête opslaat.",
    "typeOfBuilder": "Vragenlijst",
    "enableTimezoneSurvey": "Als u deze optie activeert, wordt de tijdzone-informatie vastgelegd van de persoon die de enquête indient",
    "surveyNotfication": "Enquêtemelding",
    "unsavedAlertMessage": "De enquête bevat niet-opgeslagen werk. \nWeet u zeker dat u wilt vertrekken en al het niet-opgeslagen werk wilt verwijderen?",
    "style": {
      "surveyPadding": "Enquête opvulling"
    }
  }
}
