export const defaultShadow = {
  horizontal: 0,
  vertical: 0,
  blur: 0,
  spread: 0,
  color: "FFFFFF",
};

export const defaultPadding = {
  top: 0,
  bottom: 0,
  left: 20,
  right: 20,
};

export const defaultBtnPadding = { top: 9, bottom: 9, left: 10, right: 10 };

export const defaultBorder = {
  border: 0,
  radius: 0,
  color: "FFFFFF",
  type: "none",
};

export const defaultTextStyle = {
  fontFamily: "Roboto",
  color: "000000",
  fontSize: 40,
  weight: 400,
  bgColor: "FFFFFF",
  border: defaultBorder,
  shadow: defaultShadow,
  padding: defaultPadding,
};
export const getTextBgColor = (bgColor: any, color: any, formBg: any) => {
  if (bgColor) {
    return bgColor;
  } else if (color === "FFFFFF") {
    return formBg;
  }
  return "FFFFFF";
};

export const getBorderBasedOnCurrentSetup = (border, bgColor = "FFFFFF") => {
  const { border: borderSize, type: borderType, color: borderColor } = border;
  return {
    borderSize: borderSize == 0 ? 1 : borderSize,
    borderType: borderType === "none" ? "solid" : borderType,
    borderColor: bgColor === "FFFFFF" && borderColor === "FFFFFF" ? "000000" : borderColor,
  };
};

export const getBorder = (DBborder) => {
  const { color, radius, type, border } = defaultBorder;
  return {
    border: DBborder?.border || border,
    color: DBborder?.color || color,
    radius: DBborder?.radius || radius,
    type: DBborder?.type || type,
  };
};

export const defaultShortLabel = {
  color: "464d5f",
  fontFamily: "Roboto",
  fontSize: 12,
  fontWeight: 300,
};

export const createFieldCSS = (fieldStyle: any, inputStyleType = "box") => {
  const isLineEnabled = inputStyleType === "line" ? true : false;
  return `
  #_builder-form .form-builder--item input[type=text][class=form-control],#_builder-form .form-builder--item .date-picker-custom-style,#_builder-form .form-builder--item input[type=number]{
    background-color: #${fieldStyle.bgColor} !important;
    color: #${fieldStyle.fontColor} !important;
    ${isLineEnabled ? "border-bottom" : "border"}: ${fieldStyle.border.border}px ${
    fieldStyle.border.type
  } #${fieldStyle.border.color} !important;
    border-radius: ${isLineEnabled ? 0 : fieldStyle.border.radius}px !important;
    padding: ${fieldStyle.padding.top}px ${fieldStyle.padding.right}px ${
    fieldStyle.padding.bottom
  }px ${fieldStyle.padding.left}px !important;
    box-shadow: ${fieldStyle.shadow.horizontal}px ${fieldStyle.shadow.vertical}px ${
    fieldStyle.shadow.blur
  }px ${fieldStyle.shadow.spread}px #${fieldStyle.shadow.color};
    font-family: '${fieldStyle.placeholderFontFamily}';
    font-size: ${fieldStyle.placeholderFontSize}px;
    font-weight: ${fieldStyle.placeholderFontWeight};
    background-clip: inherit !important;
  }
  #_builder-form textarea {
    background-color: #${fieldStyle.bgColor} !important;
    color: #${fieldStyle.fontColor} !important;
    ${isLineEnabled ? "border-bottom" : "border"}: ${fieldStyle.border.border}px ${
    fieldStyle.border.type
  } #${fieldStyle.border.color} !important;
    border-radius: ${isLineEnabled ? 0 : fieldStyle.border.radius}px !important;
    padding: ${fieldStyle.padding.top}px ${fieldStyle.padding.right}px ${
    fieldStyle.padding.bottom
  }px ${fieldStyle.padding.left}px !important;
    box-shadow: ${fieldStyle.shadow.horizontal}px ${fieldStyle.shadow.vertical}px ${
    fieldStyle.shadow.blur
  }px ${fieldStyle.shadow.spread}px #${fieldStyle.shadow.color} !important;
    font-family: '${fieldStyle.placeholderFontFamily}';
    font-size: ${fieldStyle.placeholderFontSize}px;
    font-weight: ${fieldStyle.placeholderFontWeight};
    background-clip: inherit !important;
  }
  #_builder-form input[type=tel],#_builder-form input[type=email],#_builder-form .multiselect .multiselect__tags{
    background-color: #${fieldStyle.bgColor} !important;
    color: #${fieldStyle.fontColor} !important;
    border: ${
      isLineEnabled
        ? "none;"
        : `${fieldStyle.border.border}px ${fieldStyle.border.type} #${fieldStyle.border.color} !important;`
    } 
    ${isLineEnabled ? "border-bottom" : "border"}: ${fieldStyle.border.border}px ${
    fieldStyle.border.type
  } #${fieldStyle.border.color} !important;
    border-radius: ${isLineEnabled ? 0 : fieldStyle.border.radius}px !important;
    padding: ${fieldStyle.padding.top}px ${fieldStyle.padding.right}px ${
    fieldStyle.padding.bottom
  }px ${fieldStyle.padding.left}px !important;
    box-shadow: ${fieldStyle.shadow.horizontal}px ${fieldStyle.shadow.vertical}px ${
    fieldStyle.shadow.blur
  }px ${fieldStyle.shadow.spread}px #${fieldStyle.shadow.color};
    font-family: '${fieldStyle.placeholderFontFamily}';
    font-size: ${fieldStyle.placeholderFontSize}px;
    font-weight: ${fieldStyle.placeholderFontWeight};
    background-clip: inherit !important;
  }
  #_builder-form .multi_select_form {
    border-radius: ${isLineEnabled ? 0 : fieldStyle.border.radius}px !important;
  }
  #_builder-form .iti--allow-dropdown input, .iti--allow-dropdown input[type=tel]{
    padding-left: ${
      parseFloat(isLineEnabled ? fieldStyle.padding.left + 20 : fieldStyle.padding.left) + 30
    }px !important;
  }
  #_builder-form .countryphone {
    height: inherit;
  }


  #_builder-form .form-builder--item .date-picker-custom-style input[type=text],  #_builder-form .form-builder--item .multiselect .multiselect__placeholder {
    padding:0;
    background-color: #${fieldStyle.bgColor};
    color: #${fieldStyle.fontColor};
    font-size: ${fieldStyle.placeholderFontSize}px;
  }
  #_builder-form .form-builder--item .multiselect .multiselect__input{
    background-color: #${fieldStyle.bgColor} !important;
  }
  #_builder-form .form-builder--item .multiselect .multiselect__select{
    background: transparent;
    z-index:10;
  }
  #_builder-form .form-builder--item .multiselect ,.multiselect__single{
    padding:0 !important;
    margin:0 !important;
    min-height: 24px;
    color:  #${fieldStyle.fontColor} !important;
    background-color: #${fieldStyle.bgColor} !important;
    font-family: '${fieldStyle.placeholderFontFamily}';
    font-size: ${fieldStyle.placeholderFontSize}px;
    font-weight: ${fieldStyle.placeholderFontWeight};
  }
  #_builder-form .form-builder--item  .multiselect__placeholder {
    padding:0 !important;
    margin:0 !important;
    min-height: 24px;
    color:  #${fieldStyle.placeholderColor} !important;
    background-color: #${fieldStyle.bgColor} !important;
    font-family: '${fieldStyle.placeholderFontFamily}';
    font-size: ${fieldStyle.placeholderFontSize}px;
    font-weight: ${fieldStyle.placeholderFontWeight};
  }
  #_builder-form .field-container{
    width:100%;
    max-width: ${fieldStyle.width}px;
  }
  #_builder-form ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #${fieldStyle.fontColor};
  font-family: '${fieldStyle.placeholderFontFamily}';
  font-size: ${fieldStyle.placeholderFontSize}px;
  font-weight: ${fieldStyle.placeholderFontWeight};

  opacity: 1; /* Firefox */
  }
  #_builder-form ::placeholder {
    color: #${fieldStyle.placeholderColor} !important;
    font-family: '${fieldStyle.placeholderFontFamily}';
    font-size: ${fieldStyle.placeholderFontSize}px;
    font-weight: ${fieldStyle.placeholderFontWeight};
  }
  #_builder-form :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #${fieldStyle.placeholderColor} !important;
    font-family: '${fieldStyle.placeholderFontFamily}';
    font-size: ${fieldStyle.placeholderFontSize}px;
    font-weight: ${fieldStyle.placeholderFontWeight};
  }
  #_builder-form ::-ms-input-placeholder { /* Microsoft Edge */
    color: #${fieldStyle.placeholderColor} !important;
    font-family: '${fieldStyle.placeholderFontFamily}';
    font-size: ${fieldStyle.placeholderFontSize}px;
    font-weight: ${fieldStyle.placeholderFontWeight};
  }

  #_builder-form label{ 
    color:#${fieldStyle.labelColor};
    font-family: '${fieldStyle.labelFontFamily}';
    font-size: ${fieldStyle.labelFontSize}px;
    font-weight: ${fieldStyle.labelFontWeight};
  }
  #_builder-form .short-label{ 
    color:#${fieldStyle.shortLabel.color};
    font-family: '${fieldStyle.shortLabel.fontFamily}';
    font-size: ${fieldStyle.shortLabel.fontSize}px;
    font-weight: ${fieldStyle.shortLabel.fontWeight};
    -webkit-font-smoothing: auto;
  }
  #_builder-form .form-builder--item .payment-suggestion-tag-container {
    background-color: #${fieldStyle.bgColor};
    color: #${fieldStyle.fontColor} !important;
    font-family: '${fieldStyle.placeholderFontFamily}';
    font-size: ${fieldStyle.placeholderFontSize}px;
    font-weight: ${fieldStyle.placeholderFontWeight};
      box-shadow: ${fieldStyle.shadow.horizontal}px ${fieldStyle.shadow.vertical}px ${
    fieldStyle.shadow.blur
  }px ${fieldStyle.shadow.spread}px #${fieldStyle.shadow.color};
  }
  #_builder-form .product-summary-amount-large, #order-confirmation .product-summary-amount-large {
    color: #${fieldStyle?.labelColor};
    font-size: ${parseFloat(fieldStyle.labelFontSize) + 4}px;
    font-weight: ${parseFloat(fieldStyle.labelFontWeight) + 200};
    font-family: ${fieldStyle.labelFontFamily};
    line-height: 1.5rem;
  }
  #_builder-form .product-summary-amount-normal, #order-confirmation .product-summary-amount-normal {
    color: #${fieldStyle?.labelColor};
    font-size: ${parseFloat(fieldStyle.labelFontSize)}px;
    font-weight: ${parseFloat(fieldStyle.labelFontWeight) + 100};
    font-family: ${fieldStyle.labelFontFamily};
    line-height: 1.5rem;
  }
  #_builder-form .product-summary-label-bold, #order-confirmation .product-summary-label-bold{
    color: #${fieldStyle?.labelColor};
    font-size: ${parseFloat(fieldStyle.labelFontSize)}px;
    font-weight: ${parseFloat(fieldStyle.labelFontWeight) + 200};
    font-family: ${fieldStyle.labelFontFamily};
    line-height: 1.5rem;
  }
  #_builder-form .crossed-amount {
    color: #${fieldStyle?.labelColor};
    font-size: ${parseFloat(fieldStyle.labelFontSize) + 2}px;
    font-weight: ${parseFloat(fieldStyle.labelFontWeight) + 100};
    font-family: ${fieldStyle.labelFontFamily};
    line-height: 1.5rem;
  }
  #_builder-form .product-summary-label-large, #order-confirmation .product-summary-label-large{
    color: #${fieldStyle?.labelColor};
    font-size: ${parseFloat(fieldStyle.labelFontSize) + 2}px;
    font-weight: ${parseFloat(fieldStyle.labelFontWeight) + 100};
    font-family: ${fieldStyle.labelFontFamily};
    line-height: 1.575rem;
  }
  #_builder-form .product-summary-label-normal, #order-confirmation .product-summary-label-normal{
    color: #${fieldStyle?.labelColor};
    font-size: ${parseFloat(fieldStyle.labelFontSize)}px;
    font-weight: ${parseFloat(fieldStyle.labelFontWeight)};
    font-family: ${fieldStyle.labelFontFamily};
    line-height: 1.575rem;
  }
  #_builder-form .product-summary-label-small, #order-confirmation .product-summary-label-small{
    color: #${fieldStyle?.labelColor};
    font-size: ${parseFloat(fieldStyle.labelFontSize) - 2}px;
    font-weight: ${parseFloat(fieldStyle.labelFontWeight)};
    font-family: ${fieldStyle.labelFontFamily};
    line-height: 1.575rem;
  }
  #_builder-form .variant-tag {
    color: #${fieldStyle?.labelColor};
    font-size: ${parseFloat(fieldStyle.labelFontSize) - 1}px;
    font-weight: ${parseFloat(fieldStyle.labelFontWeight)};
    font-family: ${fieldStyle.labelFontFamily};
    line-height: 1.5rem;
  }
  #_builder-form .selected-tag {
    background-color: #${fieldStyle?.activeTagBgColor} !important;
  }
  #_builder-form .payment-tag, #_builder-form .quantity-container-counter { 
    box-shadow: ${fieldStyle.shadow.horizontal}px ${fieldStyle.shadow.vertical}px ${
    fieldStyle.shadow.blur
  }px ${fieldStyle.shadow.spread}px #${fieldStyle.shadow.color};
    background-color : #${fieldStyle.bgColor}; 
  }
  #_builder-form .quantity-container-counter  {
    padding-top: ${fieldStyle.padding.top - 2}px !important;
    padding-bottom:  ${fieldStyle.padding.bottom - 2}px !important;
  }
  #_builder-form .quantity-text {
    font-size: ${fieldStyle.placeholderFontSize}px !important;
  }
  `;
};

export const defaultButtonStyle = {
  bgColor: "18BD5BFF",
  color: "FFFFFF",
  subTextColor: "FFFFFF",
  border: 0,
  radius: 0,
  borderColor: "000000",
  fontSize: 14,
  subTextFontSize: 14,
  weight: 600,
  subTextWeight: 200,
  borderType: "solid",
  shadow: {
    color: "FFFFFF",
    horizontal: 0,
    vertical: 0,
    blur: 0,
    spread: 0,
  },
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  fontFamily: "Roboto",
  subTextFontFamily: "Roboto",
  submitSubText: "",
};

export const defaultPaymentSettings = {
  liveModeOn: false,
  suggestionOn: true,
  products: [],
  layout: 1,
  paymentType: "product",
  amountPlaceHolder: "",
  suggestedAmount: [4.99, 5.99, 9.99],
  enableOtherAmount: true,
  required: true,
  currency: { code: "USD", symbol: "$" },
  tagActiveBGColor: "009ef426",
  footer: {
    message: "*100% secure & safe payments*",
    hidden: false,
  },
  coupon: {
    enableCoupon: false,
    couponAlignment: "right",
  },
  selectionType: "checkbox",
};

export const defaultResultTemplate = {
  tiers: [
    {
      label: "Low",
      color: "#F04438",
      fromPercent: 0,
      toPercent: 39,
    },
    {
      label: "Medium",
      color: "#F79009",
      fromPercent: 40,
      toPercent: 79,
    },
    {
      label: "High",
      color: "#12B76A",
      fromPercent: 80,
      toPercent: 100,
    },
  ],
  headerSettings: {
    enableBusinessName: true,
    headerLayout: "default",
    headerImage: "",
    imageWidth: "32",
    imageHeight: "32",
    marginPadding: {
      margin: {
        top: "auto",
        bottom: "auto",
        left: "auto",
        right: "auto",
      },
      padding: {
        top: "16",
        bottom: "16",
        left: "16",
        right: "16",
      },
    },
    headingHtml: '<p><span style="font-size: 32px">Default Quiz</span></p>',
    backgroundColor: "FFFFFF",
  },
  overallScoreSettings: {
    dynamicContent: true,
    content: {
      Low: "Thank you for taking the time to complete this quiz.",
      Medium: "Thank you for taking the time to complete this quiz.",
      High: "Thank you for taking the time to complete this quiz.",
    },
    overallScoreTitle: {
      Low: "Overall score",
      Medium: "Overall score",
      High: "Overall score",
    },
    staticContent: {
      content: "Thank you for taking the time to complete this quiz.",
      overallScoreTitle: "Overall score",
    },
    scorePosition: "right",
    showOverallScore: true,
    overallScoreFormat: "percentage",
    showTiers: true,
    marginPadding: {
      margin: {
        top: "auto",
        bottom: "auto",
        left: "auto",
        right: "auto",
      },
      padding: {
        top: "16",
        bottom: "16",
        left: "16",
        right: "16",
      },
    },
    backgroundColor: "FFFFFF",
  },
  categoryScoreSettings: {
    dynamicContent: true,
    heading: {
      Low: "Thank you for taking the time to complete this quiz.",
      Medium: "Thank you for taking the time to complete this quiz.",
      High: "Thank you for taking the time to complete this quiz.",
    },
    staticContent: {
      heading: "Thank you for taking the time to complete this quiz.",
    },
    orderCategories: "highestOrder",
    showScore: true,
    scoreFormat: "percentage",
    marginPadding: {
      margin: {
        top: "auto",
        bottom: "auto",
        left: "auto",
        right: "auto",
      },
      padding: {
        top: "32",
        bottom: "32",
        left: "24",
        right: "24",
      },
    },
    sectionBackground: "FFFFFF",
    categoryBackground: "FFFFFF",
  },
  ctaSettings: {
    dynamicContent: true,
    heading: {
      Low: '<p><strong>Want to talk about your results?</strong></p><p><span style="font-size: 14px; color: rgba(71,84,103,1) !important; color: rgba(71,84,103,1) !important">If you\'d like to learn more about your score and what it means for your business lets jump on a zoom call</span></p>',
      Medium:
        '<p><strong>Want to talk about your results?</strong></p><p><span style="font-size: 14px; color: rgba(71,84,103,1) !important; color: rgba(71,84,103,1) !important">If you\'d like to learn more about your score and what it means for your business lets jump on a zoom call</span></p>',
      High: '<p><strong>Want to talk about your results?</strong></p><p><span style="font-size: 14px; color: rgba(71,84,103,1) !important; color: rgba(71,84,103,1) !important">If you\'d like to learn more about your score and what it means for your business lets jump on a zoom call</span></p>',
    },
    buttonText: {
      Low: "Subscribe",
      Medium: "Subscribe",
      High: "Subscribe",
    },
    buttonLink: {
      Low: "www.example.com",
      Medium: "www.example.com",
      High: "www.example.com",
    },
    staticContent: {
      heading:
        '<p><strong>Want to talk about your results?</strong></p><p><span style="font-size: 14px; color: rgba(71,84,103,1) !important; color: rgba(71,84,103,1) !important">If you\'d like to learn more about your score and what it means for your business lets jump on a zoom call</span></p>',
      buttonText: "Subscribe",
      buttonLink: "",
    },
    buttonColor: "155EEF",
    buttonTextColor: "FFFFFF",
    marginPadding: {
      margin: {
        top: "auto",
        bottom: "auto",
        left: "auto",
        right: "auto",
      },
      padding: {
        top: "32",
        bottom: "32",
        left: "24",
        right: "24",
      },
    },
    backgroundColor: "FFFFFF",
    categoryBackground: "FFFFFF",
  },
  footerSettings: {
    enableBusinessName: true,
    footerImage: "",
    imageWidth: "32",
    imageHeight: "32",
    showAddSocials: true,
    socialLinks: [
      {
        label: "Google",
        value: "www.google.com",
        id: 0,
      },
    ],
    sticky: true,
    allowRowsToTakeFullWidth: true,
    marginPadding: {
      margin: {
        top: "auto",
        bottom: "auto",
        left: "auto",
        right: "auto",
      },
      padding: {
        top: "16",
        bottom: "16",
        left: "16",
        right: "16",
      },
    },
    backgroundImage: "",
    backgroundColor: "FFFFFF",
    footerHTML: "<p>Default Quiz</p>",
  },
};

export const defaultAddressSettings = {
  autoCompleteEnabled: true,
  required: true,
  children: [],
  label: "Address",
  placeholder: "Search address",
};
