<template>
  <div class="flex flex-col items-start gap-3 self-stretch">
    <div class="sub-settings-header">
      <div class="font-medium">{{ t("common.payment.footerInfo") }}</div>
    </div>
    <div class="flex flex-col items-start justify-between self-stretch">
      <div class="flex space-x-3">
        <span class="switch-handle mb-1 block text-sm font-medium text-gray-700">{{
          t("common.placeholder")
        }}</span>
      </div>
      <UIInput
        id="placeholder"
        v-model="store.payment.footer.message"
        class="standard-input-ghl"
        @update:model-value="store.anyUnsavedChanges = true"
        @blur="store.addToHistory()"
      ></UIInput>
    </div>
    <UICheckbox
      id="fieldCheckbox"
      v-model:checked="store.payment.footer.hidden"
      @update:checked="store.anyUnsavedChanges = true"
      @blur="store.addToHistory()"
      >{{ t("common.hidden") }}</UICheckbox
    >
  </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { UICheckbox, UIInput, UITextMdMedium } from "@gohighlevel/ghl-ui";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const store = useAppStore();
</script>
