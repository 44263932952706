<script setup lang="ts">
import { useEmitter } from "@/composition/useEmitter";
import { PRODUCT_TYPE } from "@/util/constants";
import { XCloseIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton, UIDrawer, UITextSmNormal } from "@gohighlevel/ghl-ui";
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import SidebarElements from "./SidebarElements.vue";

const { t } = useI18n();

const props = defineProps({
  builderType: {
    type: String,
    default: PRODUCT_TYPE.FORM,
  },
});
const show = ref(false);
const emitter = useEmitter();

const toggleOverlay = () => {
  show.value = !show.value;
  emitter.emit("close-email-side-bar");
};

const openLeftSidebar = () => {
  show.value = true;
};

const closeOverlay = () => {
  show.value = false;
};
const builderTypeConvert = computed(() => {
  if (props.builderType === PRODUCT_TYPE.FORM) {
    return t("form.formElement");
  }
  if (props.builderType === PRODUCT_TYPE.SURVEY) {
    return t("survey.surveyElement");
  }
  return t("quiz.quizElement");
});

onBeforeMount(() => {
  emitter.on("toggle-left-side-bar", toggleOverlay);
  emitter.on("close-left-side-bar", closeOverlay);
  emitter.on("open-left-side-bar", openLeftSidebar);
});

onBeforeUnmount(() => {
  emitter.off("toggle-left-side-bar", toggleOverlay);
  emitter.off("close-left-side-bar", closeOverlay);
});

onMounted(() => {
  show.value = true;
});
</script>

<template>
  <div>
    <div>
      <UIDrawer
        id="hl-builder-element-layout"
        :show="show"
        :width="370"
        placement="left"
        :show-mask="false"
        to="#builder-inner-container"
        display-directive="show"
        class="border-gray-100"
      >
        <div class="flex w-full justify-between p-2">
          <UITextSmNormal>{{ builderTypeConvert }}</UITextSmNormal>
          <UIButton
            id="hl-builder-creator-header-close"
            :quaternary="true"
            text
            @click="closeOverlay"
          >
            <XCloseIcon class="h-5 w-5 text-gray-800" />
          </UIButton>
        </div>
        <SidebarElements></SidebarElements>
      </UIDrawer>
    </div>
  </div>
</template>

<style scoped>
.close-icon-element {
  right: 18px;
  top: 18px;
  position: absolute;
  cursor: pointer;
}
</style>
