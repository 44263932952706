{
  "common": {
    "addCustomFields": "Agregar campos personalizados",
    "addElement": "Agregar elemento",
    "addUrlLike": "Agregue URL válidas como",
    "address": "DIRECCIÓN",
    "agencyBranding": "Marca de agencia",
    "alert": "Alerta",
    "alignment": "Alineación",
    "altText": "Texto alternativo",
    "back": "Atrás",
    "background": "Fondo",
    "backgroundImage": "Imagen de fondo",
    "backgroundImageTooltipMessage": "La imagen de fondo está destinada a cubrir toda la página, proporcionando un fondo visualmente atractivo para el formulario. \nPara ver la imagen de fondo, haga clic en la opción Vista previa.",
    "blur": "Difuminar",
    "border": "Borde",
    "borderType": "Tipo de borde",
    "bottom": "Abajo",
    "buttonStyle": "Estilo de botón",
    "cancel": "Cancelar",
    "cancelKeep": "Cancelar, mantener",
    "color": "Color",
    "conditionalLogicAlertOnSubmit": "La lógica condicional tendrá prioridad sobre el mensaje/redireccionamiento aquí",
    "contactInfo": "Datos de contacto",
    "cornerRadius": "Radio de esquina",
    "customCSS": "CSS personalizado",
    "customCSSTooltip": "El CSS personalizado tiene prioridad sobre el estilo del formulario",
    "customFieldSearch": "Buscar por nombre",
    "customFields": "Campos Personalizados",
    "customized": "Personalizado",
    "delete": "Borrar",
    "doNotAddRelativePath": "No agregue rutas relativas como",
    "doNotShowMessage": "No volver a mostrar",
    "done": "Hecho",
    "editHTML": "Editar HTML",
    "embed": "Empotrar",
    "enableCountryPicker": "Habilitar selector de países",
    "enterSourceValue": "Introduzca el valor de origen",
    "facebookPixelEvents": "Eventos de píxeles de Facebook",
    "facebookPixelID": "ID de píxel de Facebook",
    "facebookPixelIDMessage": "Ignore este campo si planea utilizar este formulario/encuesta dentro de un embudo",
    "fieldDescription": "campo Descripción",
    "fieldStyle": "Estilo de campo",
    "fieldTitle": "Título del campo",
    "fontColor": "Color de fuente",
    "fontFamily": "Familia tipográfica",
    "fontFamilyTooltip": "Builder admite todo tipo de fuentes si no puede verlas en la lista. \nPor favor búscalo en el menú desplegable",
    "fullWidth": "Ancho completo",
    "headerImage": "Imagen de cabecera",
    "headerImageTooltipMessage": "La imagen del encabezado está diseñada para cubrir todo el ancho del formulario y permanece ubicada en la parte superior del formulario. \nPara ver la imagen del encabezado, haga clic en la opción Vista previa.",
    "hidden": "Oculto",
    "hiddenValue": "Valor oculto",
    "horizontal": "Horizontal",
    "htmlEditorBodyMessage": "Añade tu código aquí",
    "htmlEditorHeader": "JAVASCRIPT/HTML PERSONALIZADO",
    "htmlMessage": "El HTML personalizado va aquí.",
    "iframeEmbed": "Insertar",
    "imageHeight": "Altura de imagen",
    "imageUploadMessage": "Haga clic o arrastre un archivo de imagen a esta área para cargarlo.",
    "imageWidth": "ancho de la imagen",
    "inputStyle": "Estilo de entrada",
    "integrate": "Integrar",
    "label": "Etiqueta",
    "layout": "Disposición",
    "left": "Izquierda",
    "link": "Enlace",
    "logic": "Lógica",
    "miscellaneous": "Misceláneas",
    "onSubmit": "Al enviar",
    "onpageView": "Vista en página",
    "options": "Opciones",
    "or": "o",
    "padding": "Relleno",
    "personalInfo": "Información personal",
    "placeholder": "Marcador de posición",
    "placeholderFacebookPixelIdInput": "Ingrese el ID de píxel de Facebook",
    "pleaseInput": "Por favor, input",
    "preview": "Avance",
    "proceed": "Proceder",
    "queryKey": "Clave de consulta",
    "querykeyTooltipMessage": "La clave de consulta que se puede utilizar como parámetro de URL para completar este campo",
    "quickAdd": "Agregar rápido",
    "required": "Requerido",
    "right": "Bien",
    "save": "Ahorrar",
    "saving": "Ahorro",
    "shadow": "Sombra",
    "shortLabel": "Etiqueta corta",
    "showLabel": "Mostrar etiqueta",
    "size": "Tamaño",
    "spread": "Desparramar",
    "stickyContact": "Contacto pegajoso",
    "stickyContactMessage": "El contacto fijo completará automáticamente la información de contacto ingresada previamente para que no tengas que ingresarla dos veces.",
    "styles": "Estilos",
    "subText": "Trasfondo",
    "submit": "Entregar",
    "text": "Texto",
    "textStyle": "Estilo de texto",
    "themes": {
      "alertThemeMessage": "Cambiar de tema resultará en la pérdida de todas las modificaciones de estilo actuales.",
      "removeTheme": "Quitar tema",
      "themes": "Temas",
      "useTheme": "Usar tema"
    },
    "top": "Arriba",
    "useGDPRCompliantFont": "Utilice una fuente compatible con GDPR",
    "vertical": "Vertical",
    "weight": "Peso",
    "width": "Ancho",
    "yesSave": "Si, guardar",
    "box": "Caja",
    "line": "Línea",
    "message": "Mensaje",
    "openUrl": "Abrir URL",
    "borderTypeList": {
      "dotted": "Punteado",
      "none": "Ninguno",
      "solid": "Sólido",
      "dashed": "discontinuo"
    },
    "button": "Botón",
    "enableTimezone": "Habilitar zona horaria",
    "emailNotification": "Notificación de correo electrónico",
    "emailNotificationSubheader": "Reciba un correo electrónico cuando alguien complete su formulario",
    "subject": "Sujeto",
    "emailTo": "Email para)",
    "senderName": "Nombre del remitente",
    "senderNameWarning": "Su (nombre de subcuenta) se agregará si se deja en blanco",
    "emailTooltip": "La primera dirección de correo electrónico está designada para el campo \"Para\". \nCualquier dirección de correo electrónico posterior se incluirá en el campo 'Cc'.",
    "emailError": "Se requiere al menos una dirección de correo electrónico",
    "clear": "Claro",
    "showNotificationsSettings": "Mostrar configuración de notificaciones",
    "emailServicePaid": "Esta función es un servicio pago e incurrirá en cargos según su uso.",
    "integration": "Integración",
    "payment": {
      "connect": "Conectar",
      "connected": "Conectado",
      "footerInfo": "Información de pie de página",
      "includeOtherAmount": "Incluir otra cantidad",
      "live": "Vivir",
      "paymentFields": "Campos de pago",
      "selectCurrency": "Seleccione el tipo de moneda",
      "status": "Estado",
      "suggestAnAmount": "Sugerir una cantidad",
      "test": "Prueba",
      "liveModeOn": "Modo en vivo",
      "customAmount": "Cantidad personalizada",
      "paymentType": "Tipo de pago",
      "product": {
        "addProduct": "Agregar producto",
        "clickHere": "haga clic aquí",
        "description": "Descripción",
        "editThisProduct": "Para editar este producto",
        "image": "Imagen",
        "layoutSettings": "Opciones de diseño",
        "maxProductError": "Se permiten máximo 20 productos en un formulario.",
        "productSelectPlaceholder": "Por favor seleccione un producto",
        "productSettings": "Configuración del producto",
        "selectProduct": "Seleccionar producto",
        "show": "Espectáculo",
        "singleColumn": "Una sola columna",
        "threeColumn": "Tres columnas",
        "twoColumn": "Dos columnas",
        "orderConfirmationWarning": "Se mostrará una página de confirmación del pedido una vez que el pago se haya realizado correctamente.",
        "orderConfirmation": "Confirmación del pedido",
        "createProduct": "Para crear un producto",
        "quantity": "Cantidad"
      },
      "sellProducts": "Vender productos",
      "generalSettings": "Campos generales",
      "tagActiveBGColor": "Color de etiqueta activa",
      "collectPayment": "Cobrar pago",
      "payments": "Pagos",
      "coupons": {
        "couponAlignment": "Alineación de cupones",
        "coupons": "Cupones",
        "enableCoupon": "Habilitar cupón",
        "fullWidth": "Ancho completo",
        "left": "Izquierda",
        "right": "Bien",
        "tooltipMessage": "Establezca la posición de entrada de cupones de los siguientes tipos"
      }
    },
    "autoResponder": "Respuesta automática",
    "autoResponderSubheader": "Enviar un correo electrónico automatizado a la persona que envía el formulario (el correo electrónico incluye una copia de la información que ingresó en el formulario)",
    "replyToEmail": "Responder a un correo electrónico",
    "open": "Abierto",
    "won": "Ganado",
    "lost": "Perdido",
    "abandon": "Abandonar",
    "selectAPipeline": "Seleccione una tubería",
    "stage": "Escenario",
    "status": "Estado",
    "opportunityValue": "Valor de oportunidad",
    "createNewPipeline": "Crear nueva canalización",
    "editOpportunitySettings": "Editar configuración de oportunidad",
    "contact": "Contacto",
    "opportunity": "Oportunidad",
    "add": "Agregar",
    "more": "Más",
    "opportunityHeading": "¿Dónde desea que se actualice su oportunidad?",
    "date": {
      "dateFormat": "Formato de fecha",
      "dateFormatTooltipMessage": "Seleccione un formato de fecha. \nD representa el día, M los meses y Y el año.",
      "dateSeparator": "Separador de fechas",
      "dateSeparatorTooltipMessage": "Seleccione un separador que separará el día, el mes y el año en el formato seleccionado en Formato de fecha",
      "dateSettings": "Configuración de fecha",
      "disablePicker": "Desactivar selector"
    },
    "restoreVersion": "Restaurar versión",
    "versionHistory": "Historial de versiones",
    "errorMessage": "Error, reinténtalo de nuevo",
    "version": "Versión:",
    "currentVersion": "Versión actual",
    "conditionalLogicAlertOnShowHide": "La lógica condicional tendrá prioridad sobre las configuraciones ocultas aquí.",
    "validateEmailHeader": "Validar correo electrónico",
    "validateEmailDescription": "El botón se hará visible una vez que el usuario ingrese su correo electrónico. \nEsta función está diseñada para ayudarle a generar clientes potenciales de calidad.",
    "validatePhoneHeader": "Validar teléfono",
    "validatePhoneDescription": "El botón se hará visible una vez que el usuario ingrese a su teléfono. \nEsta función está diseñada para ayudarle a generar clientes potenciales de calidad.",
    "validatePhoneWarning": "Para habilitar la verificación OTP por SMS, asegúrese de que su número de teléfono esté configurado en su configuración de ubicación",
    "style": {
      "formLayoutType": "Tipo de formulario",
      "oneColumn": "Una columna",
      "singleLine": "Linea sola",
      "twoColumn": "Dos columnas",
      "fieldWidth": "Ancho de campo",
      "fieldSpacing": "Espaciado de campo",
      "advanceSettings": "Configuraciones avanzadas",
      "borderColor": "Color del borde",
      "borderStyle": "Estilo de borde",
      "borderWidth": "Ancho del borde",
      "color": "color",
      "colorsAndBackground": "Colores",
      "fontColor": "Color de fuente",
      "fontSize": "Tamaño de fuente",
      "fontWeight": "Peso de fuente",
      "inputBackground": "Fondo de entrada",
      "inputField": "Campo de entrada",
      "labelColor": "Color de la etiqueta",
      "layout": "Disposición",
      "placeholderColor": "Color del marcador de posición",
      "shortLabelColor": "Color de etiqueta corta",
      "labelAlignment": {
        "labelAlignment": "Alineación de etiquetas",
        "labelWidth": "Ancho de etiqueta"
      }
    },
    "replyToTooltip": "Personalice la dirección de correo electrónico predeterminada para responder usando {'{'}{'{'}contact.email{'}'}{'}'} para permitir respuestas al contacto que envía el formulario.",
    "content": "Contenido",
    "calculations": "Cálculos",
    "uploadOptions": "Opciones de carga",
    "messageStyling": "Estilo de mensaje",
    "opportunitySettingsWarning": "Configure la configuración de oportunidad haciendo clic en el botón Editar configuración de oportunidad.",
    "createContactOnPartialSubmission": "Crear contacto en envío parcial",
    "howItWorks": "¿Cómo funciona?",
    "createContactOnPartialSubmissionDescription": "Termine la diapositiva y haga clic en \"Siguiente\". \nSe creará y almacenará automáticamente un nuevo contacto.",
    "swapWarningMessageSurvey": "Las opciones de edición o intercambio afectarán este campo personalizado en todos los lugares donde se utilice el campo. \nTambién puede restablecer la lógica condicional.",
    "swapWarningMessageForm": "Las opciones de edición o intercambio afectarán este campo personalizado en todos los lugares donde se utilice el campo.",
    "addOption": "Agregar opción",
    "calculationValue": "Valor de cálculo",
    "enableCalculations": "Habilitar cálculos",
    "optionLabel": "Etiqueta de opción",
    "addressSettings": {
      "addressfields": "Campos de dirección",
      "autoComplete": "Dirección de autocompletar",
      "autoCompleteTooltip": "Proporciona la capacidad de buscar y completar automáticamente la dirección. \nEsta característica es de pago.",
      "placeholderSearch": "Buscar una dirección"
    },
    "productSelectionSubtext": "Permitir la selección de uno o varios productos",
    "multi": "Multi",
    "single": "Soltero",
    "productSelectionHeading": "Selección de productos"
  },
  "form": {
    "activateOn": "Activar en",
    "activationOptions": "Opciones de activación",
    "addConditionalLogic": "Agregar lógica condicional",
    "addFormElement": "Agregar elemento de formulario",
    "allowMinimize": "Permitir minimizar",
    "alwaysActivated": "Siempre activado",
    "alwaysShow": "Siempre muestra",
    "conditonalLogic": {
      "addNewCondition": "Agregar nueva condición",
      "alertConditionDelete": "¿Está seguro de que desea eliminar esta condición?",
      "conditionExecutedMessage": "Las condiciones se ejecutarán en orden de arriba hacia abajo.",
      "conditions": "Condiciones",
      "deleteCondition": "Eliminar condición",
      "displayCustomMessage": "Mostrar mensaje personalizado",
      "disqualifyLead": "Descalificar cliente potencial",
      "freeRoamMessage": "Eres libre de deambular sin reglas, al menos por ahora",
      "noExistingCondition": "Aún no existen condiciones",
      "openBelowUrl": "Abrir debajo de la URL",
      "redirectToUrl": "Redirigir a URL",
      "showCustomMessage": "Mostrar mensaje personalizado",
      "showHideFields": "Mostrar/ocultar campos",
      "allOperationOptions": {
        "after": "Después",
        "before": "Antes",
        "contains": "Contiene",
        "endsWith": "Termina con",
        "greaterThan": "Mas grande que",
        "isEmpty": "Esta vacio",
        "isEqualTo": "Es igual a",
        "isFilled": "Está lleno",
        "isNotEqualTo": "No es igual a",
        "lessThan": "Menos que",
        "startsWith": "Comienza con"
      },
      "logic": {
        "and": "Y",
        "if": "Si",
        "or": "O",
        "then": "Entonces"
      },
      "phoneAlertMessage": "Para buscar números de teléfono con un código de país, debe incluir explícitamente el símbolo '+' en su consulta de búsqueda.",
      "deleteAlertMessage": "¿Está seguro de que desea eliminar este campo? \nTambién se eliminará la condición asociada con este campo.",
      "deleteField": "Eliminar campo"
    },
    "minimizedTitlePlaceholder": "Título minimizado",
    "copyEmbedCode": "Copie el código insertado",
    "deactivateAfterShowing": "Desactivar después de mostrar",
    "deactivationOptions": "Opciones de desactivación",
    "deactiveOnceLeadCollected": "Desactivar una vez que se recopile el cliente potencial",
    "embedlayoutType": "Insertar tipo de diseño",
    "embedlayoutTypeSubMessage": "Seleccione un diseño para insertar en un embudo o en un sitio web externo",
    "formElement": "Elemento de formulario",
    "formName": "Nombre del formulario",
    "formSettings": "Configuración del formulario",
    "formStyle": "Estilo de formulario",
    "fullScreenMode": "Modo de pantalla completa",
    "fullScreenModetooltip": "Activarlo hará que el formulario se expanda a lo largo de todo el ancho de su contenedor, estirándose hasta el borde del área de incrustación mientras se adhiere al ancho del formulario.",
    "inline": "En línea",
    "inlineDescription": "Aparece un formulario en línea junto con el contenido de la página como elemento nativo. \nNo se superpone y no se puede cerrar ni minimizar.",
    "inlineForm": "Formulario en línea",
    "inlineHoverDescription": "Aparece un formulario en línea junto con el contenido de la página como elemento nativo. \nNo se superpone y no se puede cerrar ni minimizar.",
    "integrateForm": "Integrar formulario",
    "neverDeactivate": "Nunca desactivar",
    "onFormSubmission": "Sobre el envío del formulario",
    "politeSlideIn": "Deslizamiento cortés",
    "politeSlideInDescription": "Aparece un deslizable cortés en la parte inferior de la pantalla y desaparece una vez cerrado",
    "politeSlideInHoverDescription": "Aparece en la parte inferior de la pantalla y desaparece una vez cerrada",
    "popup": "Surgir",
    "popupDescription": "Una ventana emergente muestra el formulario como una superposición dentro de una caja de luz. \nNo se puede minimizar sino cerrar.",
    "popupHoverDescription": "Una ventana emergente muestra el formulario como una superposición dentro de una caja de luz. \nNo se puede minimizar sino cerrar.",
    "seconds": "segundos",
    "showAfter": "Mostrar después",
    "showOnScrolling": "Mostrar al desplazarse",
    "stickySidebar": "Barra lateral adhesiva",
    "stickySidebarDescription": "Aparecerá una barra lateral adhesiva en el costado de la pantalla y se puede minimizar a una pestaña que se pega al costado de la ventana.",
    "stickySidebarHoverDescription": "Aparece en el costado de la pantalla y se puede minimizar a una pestaña que se pega al costado de la ventana.",
    "thirdPartyScriptAlertMessage": "Los scripts de terceros dentro del bloque HTML pueden proporcionar una funcionalidad poderosa, pero también conllevan riesgos para la privacidad, la seguridad, el rendimiento y el comportamiento de la página. \nPor lo tanto, le recomendamos que revise el contenido de este script antes de guardarlo en el formulario.",
    "times": "veces",
    "triggerType": "Tipo de disparador",
    "typeOfBuilder": "Forma",
    "visit": "visita",
    "dragYourElementFromLeft": "Arrastra tus elementos aquí desde la izquierda.",
    "enableTimezoneForm": "Al activar esta opción se capturará la información de la zona horaria de la persona que envía el formulario.",
    "formNotification": "Notificación de formulario",
    "copyFormLink": "Copiar enlace del formulario",
    "openFormLink": "Abrir enlace del formulario",
    "unsavedAlertMessage": "El formulario tiene trabajo no guardado. \n¿Está seguro de que desea salir y descartar todo el trabajo no guardado?",
    "style": {
      "formPadding": "Relleno de formulario"
    }
  },
  "survey": {
    "addSlide": "Agregar diapositiva",
    "addSurveyElement": "Agregar elemento de encuesta",
    "backButton": "Botón de retroceso",
    "backButtonTooltipMessage": "El botón Atrás ayudará al usuario a mover la diapositiva anterior",
    "delete": "Borrar",
    "disableAnimation": "Desactivar animación",
    "disableAnimationTooltipMessage": "Al activar esto se desactivará la animación de transición de diapositivas.",
    "disableAutoNavigation": "Deshabilitar la navegación automática",
    "disableAutoNavigationTooltipMessage": "Si está activado, la encuesta no pasará automáticamente a la siguiente diapositiva.",
    "disqualifyAfterSubmit": "Descalificar después de enviar",
    "disqualifyImmediately": "Descalificar inmediatamente",
    "editFooter": "Editar pie de página",
    "footer": {
      "backgroundFill": "Relleno de fondo",
      "buttonType": "Tipo de botón",
      "buttons": "Botones",
      "enableNewFooter": "Habilitar nuevo pie de página",
      "enableProgressBar": "Habilitar barra de progreso",
      "fillComplete": "Llenar completo",
      "fillInactive": "Llenar inactivo",
      "fillNext": "Rellenar (siguiente)",
      "fillPrevious": "Rellenar (anterior)",
      "fillSubmit": "Llenar (Enviar)",
      "footer": "Pie de página",
      "footerHeight": "Altura del pie de página",
      "nextText": "Siguiente (texto)",
      "position": "Posición",
      "previousText": "Anterior (texto)",
      "stickToSurvey": "Cíñete a la encuesta",
      "submitText": "Enviar (texto)",
      "textColor": "Color de texto",
      "footerType": "Tipo de pie de página",
      "stickToCard": "Quédate con la tarjeta",
      "stickToPage": "Quédate en la página"
    },
    "footerHTML": "HTML de pie de página",
    "footerHTMLMessage": "Puede escribir CSS personalizado (usando etiqueta de estilo) y HTML dentro del mismo cuadro",
    "integrateCopyLinkMessage": "Copie el enlace a continuación y compártalo fácilmente donde desee.",
    "integrateHeader": "Integrar encuesta",
    "integrateSubHeader": "Utilice las siguientes opciones para integrar su encuesta personalizada en su sitio web",
    "jumpTo": "Salta a",
    "onSurveySubmission": "Sobre el envío de la encuesta",
    "oneQuestionAtTime": "Una pregunta a la vez",
    "progressBar": "Barra de progreso",
    "progressBarTooltipMessage": "Desactivar esto eliminará la barra de progreso de la encuesta.",
    "scrollToTop": "Vuelve al comienzo",
    "scrollToTopTooltipMessage": "Si está activada, la encuesta se desplazará hacia arriba en la siguiente diapositiva.",
    "settings": "Ajustes",
    "slideConflictAlertMessage": "Esta diapositiva contiene varias preguntas que tienen una lógica establecida. \nEsto puede provocar algún comportamiento no deseado.",
    "slideDeleteAlertMessage": "¿Estás seguro de que deseas eliminar esta diapositiva?",
    "slideDeleteBetweenAlertMessage": "Esto reorganizará las otras diapositivas. ¿Está seguro de que desea eliminar esta diapositiva?",
    "slideName": "Nombre de la diapositiva",
    "slidePosition": "Posición de diapositiva",
    "surveyElement": "Elemento de encuesta",
    "surveyName": "Nombre de la encuesta",
    "surveySettings": "Configuración de la encuesta",
    "survyeStyle": "Estilo de encuesta",
    "thirdPartyScriptAlertMessage": "Los scripts de terceros dentro del bloque HTML pueden proporcionar una funcionalidad poderosa, pero también conllevan riesgos para la privacidad, la seguridad, el rendimiento y el comportamiento de la página. \nPor lo tanto, le recomendamos que revise el contenido de este script antes de guardarlo en la encuesta.",
    "typeOfBuilder": "Encuesta",
    "enableTimezoneSurvey": "Al activar esta opción se capturará la información de la zona horaria de la persona que envía la encuesta.",
    "surveyNotfication": "Notificación de encuesta",
    "unsavedAlertMessage": "La encuesta tiene trabajo no guardado. \n¿Está seguro de que desea salir y descartar todo el trabajo no guardado?",
    "style": {
      "surveyPadding": "Relleno de encuesta"
    }
  }
}
