{
  "common": {
    "addCustomFields": "Legg til egendefinerte felt",
    "addElement": "Legg til element",
    "addUrlLike": "Legg til gyldige nettadresser som",
    "address": "Adresse",
    "agencyBranding": "Byråmerkevarebygging",
    "alert": "Varsling",
    "alignment": "Justering",
    "altText": "Alt tekst",
    "back": "Tilbake",
    "background": "Bakgrunn",
    "backgroundImage": "Bakgrunnsbilde",
    "backgroundImageTooltipMessage": "Bakgrunnsbildet er ment å dekke hele siden, og gir et visuelt tiltalende bakteppe for skjemaet. \nFor å se bakgrunnsbildet, klikk på forhåndsvisningsalternativet.",
    "blur": "Uklarhet",
    "border": "Grense",
    "borderType": "Border Type",
    "bottom": "Bunn",
    "buttonStyle": "Knappestil",
    "cancel": "Avbryt",
    "cancelKeep": "Avbryt, behold",
    "color": "Farge",
    "conditionalLogicAlertOnSubmit": "Betinget logikk vil ha forrang over meldingen/viderekoblingen her",
    "contactInfo": "Kontaktinfo",
    "cornerRadius": "Hjørneradius",
    "customCSS": "Tilpasset CSS",
    "customCSSTooltip": "Tilpasset CSS har forrang over formstiling",
    "customFieldSearch": "Søk på navn",
    "customFields": "Egendefinerte felt",
    "customized": "Tilpasset",
    "delete": "Slett",
    "doNotAddRelativePath": "Ikke legg til relative baner som",
    "doNotShowMessage": "Ikke vis igjen",
    "done": "Ferdig",
    "editHTML": "Rediger HTML",
    "embed": "Bygg inn",
    "enableCountryPicker": "Aktiver landvelger",
    "enterSourceValue": "Angi kildeverdi",
    "facebookPixelEvents": "Facebook Pixel Events",
    "facebookPixelID": "Facebook Pixel ID",
    "facebookPixelIDMessage": "Ignorer dette feltet hvis du planlegger å bruke dette skjemaet/undersøkelsen i en trakt",
    "fieldDescription": "Feltbeskrivelse",
    "fieldStyle": "Feltstil",
    "fieldTitle": "Felttittel",
    "fontColor": "Skriftfarge",
    "fontFamily": "Fontfamilie",
    "fontFamilyTooltip": "Builder støtter alle typer fonter hvis du ikke kan se det i listen. \nVennligst søk det i rullegardinmenyen",
    "fullWidth": "Full bredde",
    "headerImage": "Overskriftsbilde",
    "headerImageTooltipMessage": "Toppbildet er utformet for å dekke hele skjemaets bredde og forblir plassert øverst i skjemaet. \nFor å se overskriftsbildet, klikk på forhåndsvisningsalternativet.",
    "hidden": "Skjult",
    "hiddenValue": "Skjult verdi",
    "horizontal": "Horisontal",
    "htmlEditorBodyMessage": "Legg til koden din her",
    "htmlEditorHeader": "TILPASSET JAVASCRIPT / HTML",
    "htmlMessage": "Den tilpassede HTML-en går her",
    "iframeEmbed": "Ifrmae Embed",
    "imageHeight": "Bildehøyde",
    "imageUploadMessage": "Klikk eller dra en bildefil til dette området for å laste opp",
    "imageWidth": "Bildebredde",
    "inputStyle": "Inndatastil",
    "integrate": "Integrere",
    "label": "Merkelapp",
    "layout": "Oppsett",
    "left": "Venstre",
    "link": "Link",
    "logic": "Logikk",
    "miscellaneous": "Diverse",
    "onSubmit": "Ved innsending",
    "onpageView": "På sidevisning",
    "options": "Alternativer",
    "or": "eller",
    "padding": "Polstring",
    "personalInfo": "Personlig informasjon",
    "placeholder": "Plassholder",
    "placeholderFacebookPixelIdInput": "Skriv inn Facebook Pixel ID",
    "pleaseInput": "Vennligst skriv inn",
    "preview": "Forhåndsvisning",
    "proceed": "Fortsette",
    "queryKey": "Spørringsnøkkel",
    "querykeyTooltipMessage": "Spørringsnøkkelen som kan brukes som URL-parameter for å fylle ut dette feltet",
    "quickAdd": "Rask Legg til",
    "required": "Obligatorisk",
    "right": "Ikke sant",
    "save": "Lagre",
    "saving": "Lagrer",
    "shadow": "skygge",
    "shortLabel": "Kort etikett",
    "showLabel": "Vis etikett",
    "size": "Størrelse",
    "spread": "Spre",
    "stickyContact": "Sticky kontakt",
    "stickyContactMessage": "Sticky contact vil autofylle kontaktinformasjon som er angitt tidligere, slik at du ikke trenger å angi den to ganger.",
    "styles": "Stiler",
    "subText": "Undertekst",
    "submit": "Sende inn",
    "text": "Tekst",
    "textStyle": "Tekststil",
    "themes": {
      "alertThemeMessage": "Bytte av tema vil resultere i tap av alle gjeldende stilmodifikasjoner.",
      "removeTheme": "Fjern tema",
      "themes": "Temaer",
      "useTheme": "Bruk tema"
    },
    "top": "Topp",
    "useGDPRCompliantFont": "Bruk GDPR-kompatibel font",
    "vertical": "Vertikal",
    "weight": "Vekt",
    "width": "Bredde",
    "yesSave": "Ja, lagre",
    "box": "Eske",
    "line": "Linje",
    "message": "Beskjed",
    "openUrl": "Åpne URL",
    "borderTypeList": {
      "dotted": "Stiplet",
      "none": "Ingen",
      "solid": "Fast",
      "dashed": "Stiplet"
    },
    "button": "Knapp",
    "enableTimezone": "Aktiver tidssone",
    "emailNotification": "E-postvarsling",
    "emailNotificationSubheader": "Motta en e-post når noen fyller ut skjemaet ditt",
    "subject": "Emne",
    "emailTo": "Epost til)",
    "senderName": "Avsenders navn",
    "senderNameWarning": "Ditt (underkontonavn) vil bli lagt til hvis det står tomt",
    "emailTooltip": "Den første e-postadressen er angitt for 'Til'-feltet. \nEventuelle påfølgende e-postadresser vil bli inkludert i \"Cc\"-feltet.",
    "emailError": "Minst én e-postadresse kreves",
    "clear": "Klar",
    "showNotificationsSettings": "Vis varslingsinnstillinger",
    "emailServicePaid": "Denne funksjonen er en betalt tjeneste og vil påløpe kostnader basert på bruken din.",
    "integration": "Integrering",
    "payment": {
      "connect": "Koble",
      "connected": "Tilkoblet",
      "footerInfo": "Bunntekst info",
      "includeOtherAmount": "Inkluder annet beløp",
      "live": "Bo",
      "paymentFields": "Betalingsfelt",
      "selectCurrency": "Velg Valuta",
      "status": "Status",
      "suggestAnAmount": "Foreslå et beløp",
      "test": "Test",
      "liveModeOn": "Live-modus",
      "customAmount": "Egendefinert beløp",
      "paymentType": "Betalings type",
      "product": {
        "addProduct": "Legg til produkt",
        "clickHere": "Klikk her",
        "description": "Beskrivelse",
        "editThisProduct": "For å redigere dette produktet",
        "image": "Bilde",
        "layoutSettings": "Layoutinnstillinger",
        "maxProductError": "Maksimalt 20 produkter er tillatt i et skjema",
        "productSelectPlaceholder": "Vennligst velg et produkt",
        "productSettings": "Produktinnstillinger",
        "selectProduct": "Velg produkt",
        "show": "Forestilling",
        "singleColumn": "Enkel kolonne",
        "threeColumn": "Tre kolonner",
        "twoColumn": "To kolonner",
        "orderConfirmationWarning": "En ordrebekreftelsesside vil vises når betalingen er vellykket",
        "orderConfirmation": "Ordrebekreftelse",
        "createProduct": "For å lage et produkt",
        "quantity": "Mengde"
      },
      "sellProducts": "Selg produkter",
      "generalSettings": "Generelle felt",
      "tagActiveBGColor": "Active Tag Color",
      "collectPayment": "Samle inn betaling",
      "payments": "Betalinger",
      "coupons": {
        "couponAlignment": "Kupongjustering",
        "coupons": "Kuponger",
        "enableCoupon": "Aktiver kupong",
        "fullWidth": "Full bredde",
        "left": "Venstre",
        "right": "Ikke sant",
        "tooltipMessage": "Angi plasseringen av kuponginndata fra typene nedenfor"
      }
    },
    "autoResponder": "Autosvar",
    "autoResponderSubheader": "Send en automatisk e-post til personen som sender inn skjemaet (e-posten inkluderer en kopi av informasjonen de skrev inn i skjemaet)",
    "replyToEmail": "Svar på e-post",
    "open": "Åpen",
    "won": "Vant",
    "lost": "Tapt",
    "abandon": "Forlate",
    "selectAPipeline": "Velg en rørledning",
    "stage": "Scene",
    "status": "Status",
    "opportunityValue": "Mulighetsverdi",
    "createNewPipeline": "Opprett ny pipeline",
    "editOpportunitySettings": "Rediger mulighetsinnstillinger",
    "contact": "Kontakt",
    "opportunity": "Mulighet",
    "add": "Legg til",
    "more": "Mer",
    "opportunityHeading": "Hvor vil du at muligheten din skal bli oppdatert?",
    "date": {
      "dateFormat": "Datoformat",
      "dateFormatTooltipMessage": "Velg et datoformat. \nD står for dag, M for måneder og Y for år",
      "dateSeparator": "Datoskiller",
      "dateSeparatorTooltipMessage": "Velg en skilletegn som vil skille dag, måned og år i formatet valgt på datoformat",
      "dateSettings": "Datoinnstillinger",
      "disablePicker": "Deaktiver velgeren"
    },
    "restoreVersion": "Gjenopprett versjon",
    "versionHistory": "Versjonshistorikk",
    "errorMessage": "Feil, prøv igjen",
    "version": "Versjon:",
    "currentVersion": "Gjeldende versjon",
    "conditionalLogicAlertOnShowHide": "Betinget logikk vil ha forrang over de skjulte innstillingene her",
    "validateEmailHeader": "Bekreft e-post",
    "validateEmailDescription": "Knappen blir synlig når brukeren skriver inn e-posten sin. \nDenne funksjonen er utviklet for å hjelpe deg med å generere potensielle kunder av høy kvalitet.",
    "validatePhoneHeader": "Bekreft telefon",
    "validatePhoneDescription": "Knappen blir synlig når brukeren går inn på telefonen sin. \nDenne funksjonen er utviklet for å hjelpe deg med å generere potensielle kunder av høy kvalitet.",
    "validatePhoneWarning": "For å aktivere SMS OTP-verifisering må du sørge for at telefonnummeret ditt er konfigurert i posisjonsinnstillingene",
    "style": {
      "formLayoutType": "Skjematype",
      "oneColumn": "Én kolonne",
      "singleLine": "Enkel linje",
      "twoColumn": "To kolonner",
      "fieldWidth": "Feltbredde",
      "fieldSpacing": "Feltavstand",
      "advanceSettings": "Avanserte innstillinger",
      "borderColor": "Grensefarge",
      "borderStyle": "Border stil",
      "borderWidth": "Kantbredde",
      "color": "farge",
      "colorsAndBackground": "Farger",
      "fontColor": "Skriftfarge",
      "fontSize": "Skriftstørrelse",
      "fontWeight": "Skriftvekt",
      "inputBackground": "Inndatabakgrunn",
      "inputField": "Inndatafelt",
      "labelColor": "Etikettfarge",
      "layout": "Oppsett",
      "placeholderColor": "Plassholderfarge",
      "shortLabelColor": "Kort etikett farge",
      "labelAlignment": {
        "labelAlignment": "Etikettjustering",
        "labelWidth": "Etikettbredde"
      }
    },
    "replyToTooltip": "Tilpass standard svar-til-e-postadresse ved å bruke {'{'}{'{'}contact.email{'}'}{'}'} for å aktivere svar til kontakten som sender inn skjemaet.",
    "content": "Innhold",
    "calculations": "Beregninger",
    "uploadOptions": "Opplastingsalternativer",
    "messageStyling": "Meldingsstil",
    "opportunitySettingsWarning": "Sett opp mulighetsinnstillinger ved å klikke på knappen Rediger mulighetsinnstillinger.",
    "createContactOnPartialSubmission": "Opprett kontakt ved delvis innsending",
    "howItWorks": "Hvordan fungerer det?",
    "createContactOnPartialSubmissionDescription": "Fullfør lysbildet og klikk \"Neste\". \nEn ny kontakt opprettes og lagres automatisk.",
    "swapWarningMessageSurvey": "Redigerings- eller byttealternativer vil påvirke dette egendefinerte feltet på alle steder der feltet brukes. \nDen kan også tilbakestille betinget logikk.",
    "swapWarningMessageForm": "Redigerings- eller byttealternativer vil påvirke dette egendefinerte feltet på alle steder der feltet brukes.",
    "addOption": "Legg til alternativ",
    "calculationValue": "Beregningsverdi",
    "enableCalculations": "Aktiver beregninger",
    "optionLabel": "Alternativetikett",
    "addressSettings": {
      "addressfields": "Adressefelt",
      "autoComplete": "Autofullfør adresse",
      "autoCompleteTooltip": "Gir mulighet til å søke og automatisk fylle ut adresse. \nDenne funksjonen er avgiftsbelagt.",
      "placeholderSearch": "Søk etter en adresse"
    },
    "productSelectionSubtext": "Tillat enkelt eller flere produktvalg",
    "multi": "Multi",
    "single": "Enkelt",
    "productSelectionHeading": "Produktutvalg"
  },
  "form": {
    "activateOn": "Aktiver på",
    "activationOptions": "Aktiveringsalternativer",
    "addConditionalLogic": "Legg til betinget logikk",
    "addFormElement": "Legg til skjemaelement",
    "allowMinimize": "Tillat minimer",
    "alwaysActivated": "Alltid aktivert",
    "alwaysShow": "Alltid vis",
    "conditonalLogic": {
      "addNewCondition": "Legg til ny tilstand",
      "alertConditionDelete": "Er du sikker på at du vil slette denne betingelsen?",
      "conditionExecutedMessage": "Betingelsene vil bli utført fra ovenfra og ned ordre.",
      "conditions": "Forhold",
      "deleteCondition": "Slett tilstand",
      "displayCustomMessage": "Vis egendefinert melding",
      "disqualifyLead": "Diskvalifiser ledelsen",
      "freeRoamMessage": "Du er fri til å streife rundt uten reglene - i hvert fall foreløpig",
      "noExistingCondition": "Ingen eksisterende betingelser ennå",
      "openBelowUrl": "Åpne under URL",
      "redirectToUrl": "Omdiriger til URL",
      "showCustomMessage": "Vis egendefinert melding",
      "showHideFields": "Vis/skjul felt",
      "allOperationOptions": {
        "after": "Etter",
        "before": "Før",
        "contains": "Inneholder",
        "endsWith": "Slutter med",
        "greaterThan": "Større enn",
        "isEmpty": "Er tom",
        "isEqualTo": "Er lik",
        "isFilled": "Er fylt",
        "isNotEqualTo": "Er ikke lik",
        "lessThan": "Mindre enn",
        "startsWith": "Begynner med"
      },
      "logic": {
        "and": "Og",
        "if": "Hvis",
        "or": "Eller",
        "then": "Deretter"
      },
      "phoneAlertMessage": "For å søke etter telefonnumre med en landskode, må du eksplisitt inkludere '+'-symbolet i søket ditt.",
      "deleteAlertMessage": "Er du sikker på at du vil slette dette feltet? \nTilstanden knyttet til dette feltet vil også bli fjernet.",
      "deleteField": "Slett felt"
    },
    "minimizedTitlePlaceholder": "Minimert tittel",
    "copyEmbedCode": "Kopier innebygd kode",
    "deactivateAfterShowing": "Deaktiver etter visning",
    "deactivationOptions": "Deaktiveringsalternativer",
    "deactiveOnceLeadCollected": "Deaktiver når bly er samlet",
    "embedlayoutType": "Bygg inn layouttype",
    "embedlayoutTypeSubMessage": "Velg et oppsett som skal bygges inn i en trakt eller et eksternt nettsted",
    "formElement": "Skjemaelement",
    "formName": "Skjemanavn",
    "formSettings": "Skjemainnstillinger",
    "formStyle": "Skjemastil",
    "fullScreenMode": "Fullskjerm-modus",
    "fullScreenModetooltip": "Å slå den på vil føre til at skjemaet utvider seg over hele bredden av beholderen, og strekker seg til kanten av innebyggingsområdet mens det holder seg til skjemaets bredde.",
    "inline": "På linje",
    "inlineDescription": "Et innebygd skjema vises sammen med innholdet på siden som et integrert element. \nDen ligger ikke over og kan ikke lukkes eller minimeres.",
    "inlineForm": "Innebygd skjema",
    "inlineHoverDescription": "Et innebygd skjema vises sammen med innholdet på siden som et integrert element. \nDen ligger ikke over og kan ikke lukkes eller minimeres.",
    "integrateForm": "Integrer skjema",
    "neverDeactivate": "Aldri deaktiver",
    "onFormSubmission": "Ved innsending av skjema",
    "politeSlideIn": "Høflig innglidning",
    "politeSlideInDescription": "En høflig slide-in vises på undersiden av skjermen og forsvinner når den er lukket",
    "politeSlideInHoverDescription": "Vises på undersiden av skjermen og forsvinner når den er lukket",
    "popup": "Popup",
    "popupDescription": "En popup viser form som et overlegg inne i en lysboks. \nDen kan ikke minimeres, men lukkes.",
    "popupHoverDescription": "En popup viser form som et overlegg inne i en lysboks. \nDen kan ikke minimeres, men lukkes.",
    "seconds": "sekunder",
    "showAfter": "Vis etter",
    "showOnScrolling": "Vis ved rulling",
    "stickySidebar": "Klistret sidefelt",
    "stickySidebarDescription": "Et klebrig sidefelt vises på siden av skjermen og kan minimeres til en fane som fester seg til siden av vinduet",
    "stickySidebarHoverDescription": "Vises på siden av skjermen og kan minimeres til en flik som fester seg til siden av vinduet",
    "thirdPartyScriptAlertMessage": "Tredjepartsskript inne i HTML-blokken kan gi kraftig funksjonalitet, men de medfører også risikoer for personvern, sikkerhet, ytelse og sideatferd. \nDerfor anbefaler vi at du går gjennom innholdet i dette skriptet før du lagrer det i skjemaet.",
    "times": "ganger",
    "triggerType": "Triggertype",
    "typeOfBuilder": "Skjema",
    "visit": "besøk",
    "dragYourElementFromLeft": "Dra elementene dine hit fra venstre",
    "enableTimezoneForm": "Aktivering av dette alternativet vil fange opp tidssoneinformasjonen til personen som sender inn skjemaet",
    "formNotification": "Skjemavarsel",
    "copyFormLink": "Kopier skjemalinken",
    "openFormLink": "Åpne skjemalinken",
    "unsavedAlertMessage": "Skjemaet har ulagret arbeid. \nEr du sikker på at du vil forlate og forkaste alt ulagret arbeid?",
    "style": {
      "formPadding": "Skjemapolstring"
    }
  },
  "survey": {
    "addSlide": "Legg til lysbilde",
    "addSurveyElement": "Legg til undersøkelseselement",
    "backButton": "Tilbake knapp",
    "backButtonTooltipMessage": "Tilbake-knappen hjelper brukeren med å flytte forrige lysbilde",
    "delete": "Slett",
    "disableAnimation": "Deaktiver animasjon",
    "disableAnimationTooltipMessage": "Hvis du slår dette på, deaktiveres lysbildeovergangsanimasjonen",
    "disableAutoNavigation": "Deaktiver automatisk navigering",
    "disableAutoNavigationTooltipMessage": "Hvis slått på, vil undersøkelsen ikke automatisk gå til neste lysbilde",
    "disqualifyAfterSubmit": "Diskvalifiser etter innsending",
    "disqualifyImmediately": "Diskvalifiser umiddelbart",
    "editFooter": "Rediger bunntekst",
    "footer": {
      "backgroundFill": "Bakgrunnsfyll",
      "buttonType": "Knapptype",
      "buttons": "Knapper",
      "enableNewFooter": "Aktiver ny bunntekst",
      "enableProgressBar": "Aktiver fremdriftslinje",
      "fillComplete": "Fyll fullført",
      "fillInactive": "Fyll inaktiv",
      "fillNext": "Fyll (neste)",
      "fillPrevious": "Fyll (forrige)",
      "fillSubmit": "Fyll (Send inn)",
      "footer": "Bunntekst",
      "footerHeight": "Bunnteksthøyde",
      "nextText": "Neste (tekst)",
      "position": "Posisjon",
      "previousText": "Forrige (tekst)",
      "stickToSurvey": "Hold deg til Survey",
      "submitText": "Send inn (tekst)",
      "textColor": "Tekstfarge",
      "footerType": "Bunnteksttype",
      "stickToCard": "Hold deg til kortet",
      "stickToPage": "Hold deg til siden"
    },
    "footerHTML": "Bunntekst HTML",
    "footerHTMLMessage": "Du kan skrive tilpasset CSS (ved å bruke stiltag) og HTML i samme boks",
    "integrateCopyLinkMessage": "Kopier lenken nedenfor og del den enkelt hvor du måtte ønske.",
    "integrateHeader": "Integrer undersøkelsen",
    "integrateSubHeader": "Bruk følgende alternativer for å integrere din egendefinerte undersøkelse på nettstedet ditt",
    "jumpTo": "Hoppe til",
    "onSurveySubmission": "Ved innsending av undersøkelse",
    "oneQuestionAtTime": "Ett spørsmål om gangen",
    "progressBar": "Fremdriftslinje",
    "progressBarTooltipMessage": "Hvis du slår denne av, fjernes fremdriftslinjen fra undersøkelsen",
    "scrollToTop": "Rull til toppen",
    "scrollToTopTooltipMessage": "Hvis den er slått på, vil undersøkelsen rulle til toppen på neste lysbilde",
    "settings": "Innstillinger",
    "slideConflictAlertMessage": "Dette lysbildet inneholder flere spørsmål som har en logikk satt til dem. \nDette kan forårsake utilsiktet oppførsel",
    "slideDeleteAlertMessage": "Er du sikker på at du vil slette dette lysbildet?",
    "slideDeleteBetweenAlertMessage": "Dette vil omorganisere de andre lysbildene. Er du sikker på at du vil slette dette lysbildet?",
    "slideName": "Lysbildenavn",
    "slidePosition": "Skyveposisjon",
    "surveyElement": "Undersøkelseselement",
    "surveyName": "Navn på undersøkelsen",
    "surveySettings": "Undersøkelsesinnstillinger",
    "survyeStyle": "Undersøkelsesstil",
    "thirdPartyScriptAlertMessage": "Tredjeparts skript inne i HTML-blokken kan gi kraftig funksjonalitet, men de medfører også risiko for personvern, sikkerhet, ytelse og sideatferd. \nDerfor anbefaler vi at du går gjennom innholdet i dette skriptet før du lagrer det i undersøkelsen.",
    "typeOfBuilder": "undersøkelse",
    "enableTimezoneSurvey": "Aktivering av dette alternativet vil fange opp tidssoneinformasjonen til personen som sender inn undersøkelsen",
    "surveyNotfication": "Undersøkelsesvarsel",
    "unsavedAlertMessage": "Undersøkelsen har ulagret arbeid. \nEr du sikker på at du vil forlate og forkaste alt ulagret arbeid?",
    "style": {
      "surveyPadding": "Undersøkelsespolstring"
    }
  }
}
