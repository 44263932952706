{
  "common": {
    "addCustomFields": "Lisää mukautettuja kenttiä",
    "addElement": "Lisää elementti",
    "addUrlLike": "Lisää kelvollisia URL-osoitteita kuten",
    "address": "Osoite",
    "agencyBranding": "Toimistobrändäys",
    "alert": "Varoitus",
    "alignment": "Tasaus",
    "altText": "Vaihtoehtoinen teksti",
    "back": "Takaisin",
    "background": "Tausta",
    "backgroundImage": "Taustakuva",
    "backgroundImageTooltipMessage": "Taustakuvan on tarkoitus peittää koko sivu ja luoda visuaalisesti houkutteleva tausta lomakkeelle. \nVoit tarkastella taustakuvaa napsauttamalla Esikatselu-vaihtoehtoa.",
    "blur": "Hämärtää",
    "border": "Raja",
    "borderType": "Reunuksen tyyppi",
    "bottom": "Pohja",
    "buttonStyle": "Painikkeen tyyli",
    "cancel": "Peruuttaa",
    "cancelKeep": "Peruuta, säilytä",
    "color": "Väri",
    "conditionalLogicAlertOnSubmit": "Ehdollinen logiikka menee viestin/uudelleenohjauksen edelle",
    "contactInfo": "Yhteystiedot",
    "cornerRadius": "Kulman säde",
    "customCSS": "Mukautettu CSS",
    "customCSSTooltip": "Muokattu CSS menee lomakkeen tyylin edelle",
    "customFieldSearch": "Hae nimellä",
    "customFields": "Mukautetut kentät",
    "customized": "Mukautettu",
    "delete": "Poistaa",
    "doNotAddRelativePath": "Älä lisää suhteellisia polkuja, kuten",
    "doNotShowMessage": "Älä näytä uudelleen",
    "done": "Tehty",
    "editHTML": "Muokkaa HTML-koodia",
    "embed": "Upottaa",
    "enableCountryPicker": "Ota maanvalitsin käyttöön",
    "enterSourceValue": "Anna lähdearvo",
    "facebookPixelEvents": "Facebook Pixel -tapahtumat",
    "facebookPixelID": "Facebook Pixel ID",
    "facebookPixelIDMessage": "Ohita tämä kenttä, jos aiot käyttää tätä lomaketta/kyselyä suppilon sisällä",
    "fieldDescription": "Kentän kuvaus",
    "fieldStyle": "Kenttätyyli",
    "fieldTitle": "Kentän otsikko",
    "fontColor": "Fontin väri",
    "fontFamily": "Fonttiperhe",
    "fontFamilyTooltip": "Builder tukee kaikentyyppisiä fontteja, jos et näe sitä luettelossa. \nEtsi se alasvetovalikosta",
    "fullWidth": "Täysi leveys",
    "headerImage": "Otsikkokuva",
    "headerImageTooltipMessage": "Otsikkokuva on suunniteltu kattamaan lomakkeen koko leveys ja pysyy lomakkeen yläosassa. \nVoit tarkastella otsikkokuvaa napsauttamalla Esikatselu-vaihtoehtoa.",
    "hidden": "Piilotettu",
    "hiddenValue": "Piilotettu arvo",
    "horizontal": "Vaakasuora",
    "htmlEditorBodyMessage": "Lisää koodisi tähän",
    "htmlEditorHeader": "MUKAUTETTU JAVASCRIPT / HTML",
    "htmlMessage": "Muokattu HTML menee tänne",
    "iframeEmbed": "Ifrmae Embed",
    "imageHeight": "Kuvan korkeus",
    "imageUploadMessage": "Napsauta tai vedä kuvatiedosto tälle alueelle ladataksesi sen",
    "imageWidth": "Kuvan leveys",
    "inputStyle": "Syöttötyyli",
    "integrate": "Integroi",
    "label": "Label",
    "layout": "Layout",
    "left": "Vasen",
    "link": "Linkki",
    "logic": "Logiikka",
    "miscellaneous": "Sekalaista",
    "onSubmit": "Lähetyksessä",
    "onpageView": "Sivunäkymässä",
    "options": "Vaihtoehdot",
    "or": "tai",
    "padding": "Pehmuste",
    "personalInfo": "Henkilökohtaiset tiedot",
    "placeholder": "Paikanpitäjä",
    "placeholderFacebookPixelIdInput": "Anna Facebook Pixel ID",
    "pleaseInput": "Ole hyvä ja syötä",
    "preview": "Esikatselu",
    "proceed": "Edetä",
    "queryKey": "Kyselyavain",
    "querykeyTooltipMessage": "Kyselyavain, jota voidaan käyttää URL-parametrina tämän kentän täyttämiseen",
    "quickAdd": "Pikalisäys",
    "required": "Edellytetään",
    "right": "Oikein",
    "save": "Tallentaa",
    "saving": "Tallentaa",
    "shadow": "Varjo",
    "shortLabel": "Lyhyt etiketti",
    "showLabel": "Näytä etiketti",
    "size": "Koko",
    "spread": "Levitän",
    "stickyContact": "Tahmea kontakti",
    "stickyContactMessage": "Kiinnittyvä yhteystieto täyttää automaattisesti aiemmin annetut yhteystiedot, joten sinun ei tarvitse syöttää niitä kahdesti.",
    "styles": "Tyylit",
    "subText": "Alateksti",
    "submit": "Lähetä",
    "text": "Teksti",
    "textStyle": "Tekstin tyyli",
    "themes": {
      "alertThemeMessage": "Teeman vaihtaminen johtaa kaikkien nykyisten tyylimuutosten menettämiseen.",
      "removeTheme": "Poista teema",
      "themes": "Teemat",
      "useTheme": "Käytä teemaa"
    },
    "top": "Yläosa",
    "useGDPRCompliantFont": "Käytä GDPR-yhteensopivaa fonttia",
    "vertical": "Pystysuora",
    "weight": "Paino",
    "width": "Leveys",
    "yesSave": "Kyllä, Tallenna",
    "box": "Laatikko",
    "line": "Linja",
    "message": "Viesti",
    "openUrl": "Avaa URL-osoite",
    "borderTypeList": {
      "dotted": "Pisteellinen",
      "none": "Ei mitään",
      "solid": "Kiinteä",
      "dashed": "Katkotettu"
    },
    "button": "Painike",
    "enableTimezone": "Ota aikavyöhyke käyttöön",
    "emailNotification": "Sähköposti-ilmoitus",
    "emailNotificationSubheader": "Saat sähköpostin, kun joku täyttää lomakkeesi",
    "subject": "Aihe",
    "emailTo": "Lähettää sähköposti jollekin)",
    "senderName": "Lähettäjän nimi",
    "senderNameWarning": "Sinun (alatilisi nimi) lisätään, jos se jätetään tyhjäksi",
    "emailTooltip": "Ensimmäinen sähköpostiosoite on määritetty Vastaanottaja-kenttään. \nKaikki myöhemmät sähköpostiosoitteet sisällytetään Kopio-kenttään.",
    "emailError": "Vähintään yksi sähköpostiosoite vaaditaan",
    "clear": "Asia selvä",
    "showNotificationsSettings": "Näytä ilmoitusasetukset",
    "emailServicePaid": "Tämä ominaisuus on maksullinen palvelu, ja siitä veloitetaan käyttösi perusteella.",
    "integration": "Liittäminen",
    "payment": {
      "connect": "Kytkeä",
      "connected": "Yhdistetty",
      "footerInfo": "Alatunnisteen tiedot",
      "includeOtherAmount": "Sisällytä muu summa",
      "live": "Elää",
      "paymentFields": "Maksukentät",
      "selectCurrency": "Valitse Valuutta",
      "status": "Tila",
      "suggestAnAmount": "Ehdota summaa",
      "test": "Testata",
      "liveModeOn": "Live-tila",
      "customAmount": "Mukautettu määrä",
      "paymentType": "Maksutapa",
      "product": {
        "addProduct": "Lisää tuote",
        "clickHere": "Klikkaa tästä",
        "description": "Kuvaus",
        "editThisProduct": "Voit muokata tätä tuotetta",
        "image": "Kuva",
        "layoutSettings": "Asetteluasetukset",
        "maxProductError": "Yhdellä lomakkeella saa olla enintään 20 tuotetta",
        "productSelectPlaceholder": "Valitse tuote",
        "productSettings": "Tuoteasetukset",
        "selectProduct": "Valitse Tuote",
        "show": "Näytä",
        "singleColumn": "Yksi sarake",
        "threeColumn": "Kolme saraketta",
        "twoColumn": "Kaksi saraketta",
        "orderConfirmationWarning": "Tilausvahvistussivu tulee näkyviin, kun maksu on onnistunut",
        "orderConfirmation": "Tilausvahvistus",
        "createProduct": "Luodaksesi tuotteen",
        "quantity": "Määrä"
      },
      "sellProducts": "Myy Tuotteet",
      "generalSettings": "Yleiset kentät",
      "tagActiveBGColor": "Aktiivinen tunnisteen väri",
      "collectPayment": "Kerää Maksu",
      "payments": "Maksut",
      "coupons": {
        "couponAlignment": "Kupongin kohdistus",
        "coupons": "Kupongit",
        "enableCoupon": "Ota kuponki käyttöön",
        "fullWidth": "Täysi leveys",
        "left": "Vasen",
        "right": "Oikein",
        "tooltipMessage": "Aseta kupongin syötteen sijainti alla olevista tyypeistä"
      }
    },
    "autoResponder": "Auto Responder",
    "autoResponderSubheader": "Lähetä automaattinen sähköposti lomakkeen lähettäjälle (sähköposti sisältää kopion tiedoista, jotka hän on syöttänyt lomakkeeseen)",
    "replyToEmail": "Vastaus sähköpostiin",
    "open": "Avata",
    "won": "Voitti",
    "lost": "Kadonnut",
    "abandon": "Luopua",
    "selectAPipeline": "Valitse putki",
    "stage": "Vaihe",
    "status": "Tila",
    "opportunityValue": "Mahdollisuuden arvo",
    "createNewPipeline": "Luo uusi putki",
    "editOpportunitySettings": "Muokkaa mahdollisuuksien asetuksia",
    "contact": "Ottaa yhteyttä",
    "opportunity": "Tilaisuus",
    "add": "Lisätä",
    "more": "Lisää",
    "opportunityHeading": "Missä haluat mahdollisuutesi päivitettävän?",
    "date": {
      "dateFormat": "Päivämäärämuoto",
      "dateFormatTooltipMessage": "Valitse päivämäärän muoto. \nD merkitsi päivää, M kuukausia ja Y vuotta",
      "dateSeparator": "Päivämäärän erotin",
      "dateSeparatorTooltipMessage": "Valitse erotin, joka erottaa päivän, kuukauden ja vuoden päivämäärämuodossa valitussa muodossa",
      "dateSettings": "Päivämääräasetukset",
      "disablePicker": "Poista valitsin käytöstä"
    },
    "restoreVersion": "Palauta versio",
    "versionHistory": "Versiohistoria",
    "errorMessage": "Virhe, yritä uudelleen",
    "version": "Versio:",
    "currentVersion": "Nykyinen versio",
    "conditionalLogicAlertOnShowHide": "Ehdollinen logiikka ohittaa tässä piilotetut asetukset",
    "validateEmailHeader": "Vahvista sähköposti",
    "validateEmailDescription": "Painike tulee näkyviin, kun käyttäjä kirjoittaa sähköpostiosoitteensa. \nTämä ominaisuus on suunniteltu auttamaan sinua luomaan laadukkaita liidejä.",
    "validatePhoneHeader": "Vahvista puhelin",
    "validatePhoneDescription": "Painike tulee näkyviin, kun käyttäjä syöttää puhelimensa. \nTämä ominaisuus on suunniteltu auttamaan sinua luomaan laadukkaita liidejä.",
    "validatePhoneWarning": "Ota tekstiviestien OTP-vahvistus käyttöön varmistamalla, että puhelinnumerosi on määritetty sijaintiasetuksissasi",
    "style": {
      "formLayoutType": "Lomakkeen tyyppi",
      "oneColumn": "Yksi sarake",
      "singleLine": "Yksi rivi",
      "twoColumn": "Kaksi saraketta",
      "fieldWidth": "Kentän leveys",
      "fieldSpacing": "Kenttäväli",
      "advanceSettings": "Lisäasetukset",
      "borderColor": "Reunuksen väri",
      "borderStyle": "Reunuksen tyyli",
      "borderWidth": "Reunan leveys",
      "color": "väri",
      "colorsAndBackground": "värit",
      "fontColor": "Fontin väri",
      "fontSize": "Fonttikoko",
      "fontWeight": "Fontin paino",
      "inputBackground": "Syötä tausta",
      "inputField": "Syöttökenttä",
      "labelColor": "Etiketin väri",
      "layout": "Layout",
      "placeholderColor": "Paikkamerkkien väri",
      "shortLabelColor": "Lyhyen etiketin väri",
      "labelAlignment": {
        "labelAlignment": "Tarran kohdistus",
        "labelWidth": "Tarran leveys"
      }
    },
    "replyToTooltip": "Muokkaa oletusvastaussähköpostiosoitetta käyttämällä {'{'}{'{'}contact.email{'}'}{'}'}, jotta voit vastata lomakkeen lähettäneelle yhteyshenkilölle.",
    "content": "Sisältö",
    "calculations": "Laskelmat",
    "uploadOptions": "Latausasetukset",
    "messageStyling": "Viestin tyyli",
    "opportunitySettingsWarning": "Määritä mahdollisuuksien asetukset napsauttamalla Muokkaa mahdollisuuksien asetuksia -painiketta.",
    "createContactOnPartialSubmission": "Luo yhteystieto osittaisen lähetyksen yhteydessä",
    "howItWorks": "Miten se toimii?",
    "createContactOnPartialSubmissionDescription": "Viimeistele dia ja napsauta \"Seuraava\". \nUusi yhteystieto luodaan ja tallennetaan automaattisesti.",
    "swapWarningMessageSurvey": "Vaihtoehtojen muokkaaminen tai vaihtaminen vaikuttaa tähän mukautettuun kenttään kaikissa paikoissa, joissa kenttää käytetään. \nSe voi myös nollata ehdollisen logiikan.",
    "swapWarningMessageForm": "Vaihtoehtojen muokkaaminen tai vaihtaminen vaikuttaa tähän mukautettuun kenttään kaikissa paikoissa, joissa kenttää käytetään.",
    "addOption": "Lisää vaihtoehto",
    "calculationValue": "Laskenta-arvo",
    "enableCalculations": "Ota laskutoimitukset käyttöön",
    "optionLabel": "Vaihtoehtojen etiketti",
    "addressSettings": {
      "addressfields": "Osoitekentät",
      "autoComplete": "Täydennetty osoite automaattisesti",
      "autoCompleteTooltip": "Tarjoaa mahdollisuuden etsiä ja täyttää osoite automaattisesti. \nTämä ominaisuus on maksullinen.",
      "placeholderSearch": "Etsi osoite"
    },
    "productSelectionSubtext": "Salli yhden tai useamman tuotteen valinta",
    "multi": "Multi",
    "single": "Sinkku",
    "productSelectionHeading": "Tuotteen valinta"
  },
  "form": {
    "activateOn": "Aktivoi päälle",
    "activationOptions": "Aktivointivaihtoehdot",
    "addConditionalLogic": "Lisää ehdollinen logiikka",
    "addFormElement": "Lisää lomakeelementti",
    "allowMinimize": "Salli minimoida",
    "alwaysActivated": "Aina aktivoitu",
    "alwaysShow": "Näytä aina",
    "conditonalLogic": {
      "addNewCondition": "Lisää uusi ehto",
      "alertConditionDelete": "Haluatko varmasti poistaa tämän ehdon?",
      "conditionExecutedMessage": "Ehdot toteutetaan ylhäältä alas -järjestyksessä.",
      "conditions": "ehdot",
      "deleteCondition": "Poista ehto",
      "displayCustomMessage": "Näytä mukautettu viesti",
      "disqualifyLead": "Hylkää johto",
      "freeRoamMessage": "Voit vapaasti vaeltaa ilman sääntöjä - ainakin toistaiseksi",
      "noExistingCondition": "Ei vielä olemassa olevia ehtoja",
      "openBelowUrl": "Avaa URL-osoite alla",
      "redirectToUrl": "Uudelleenohjaa URL-osoitteeseen",
      "showCustomMessage": "Näytä mukautettu viesti",
      "showHideFields": "Näytä/piilota kentät",
      "allOperationOptions": {
        "after": "Jälkeen",
        "before": "Ennen",
        "contains": "Sisältää",
        "endsWith": "Loppuu",
        "greaterThan": "Suurempi kuin",
        "isEmpty": "On tyhjä",
        "isEqualTo": "On yhtä suuri kuin",
        "isFilled": "On täytetty",
        "isNotEqualTo": "Ei ole sama kuin",
        "lessThan": "Vähemmän kuin",
        "startsWith": "Alkaa kanssa"
      },
      "logic": {
        "and": "Ja",
        "if": "Jos",
        "or": "Tai",
        "then": "Sitten"
      },
      "phoneAlertMessage": "Jos haluat etsiä puhelinnumeroita maakoodilla, sinun on sisällytettävä hakukyselyysi nimenomaisesti symboli .",
      "deleteAlertMessage": "Haluatko varmasti poistaa tämän kentän? \nMyös tähän kenttään liittyvä ehto poistetaan.",
      "deleteField": "Poista kenttä"
    },
    "minimizedTitlePlaceholder": "Minimoitu otsikko",
    "copyEmbedCode": "Kopioi upotuskoodi",
    "deactivateAfterShowing": "Poista käytöstä näyttämisen jälkeen",
    "deactivationOptions": "Deaktivointivaihtoehdot",
    "deactiveOnceLeadCollected": "Poista käytöstä, kun johto on kerätty",
    "embedlayoutType": "Upota asettelutyyppi",
    "embedlayoutTypeSubMessage": "Valitse suppiloon tai ulkoiseen verkkosivustoon upotettava asettelu",
    "formElement": "Lomake-elementti",
    "formName": "Lomakkeen nimi",
    "formSettings": "Lomakeasetukset",
    "formStyle": "Lomakkeen tyyli",
    "fullScreenMode": "Koko näytön tila",
    "fullScreenModetooltip": "Kun se kytketään päälle, lomake laajenee säiliön koko leveydelle ja venyy upotusalueen reunaan ja pysyy samalla lomakkeen leveydellä.",
    "inline": "Linjassa",
    "inlineDescription": "Sisäinen lomake näkyy sivun sisällön mukana alkuperäisenä elementtinä. \nSe ei peitä, eikä sitä voi sulkea tai pienentää.",
    "inlineForm": "Sisäänrakennettu lomake",
    "inlineHoverDescription": "Sisäinen lomake näkyy sivun sisällön mukana alkuperäisenä elementtinä. \nSe ei peitä, eikä sitä voi sulkea tai pienentää.",
    "integrateForm": "Integroi lomake",
    "neverDeactivate": "Älä koskaan deaktivoi",
    "onFormSubmission": "Lomakkeen lähettämisen yhteydessä",
    "politeSlideIn": "Kohtelias sisäänajo",
    "politeSlideInDescription": "Näytön alareunaan ilmestyy kohtelias sisäänvienti, joka katoaa, kun se suljetaan",
    "politeSlideInHoverDescription": "Näkyy näytön alareunassa ja katoaa, kun se suljetaan",
    "popup": "Ponnahdusikkuna",
    "popupDescription": "Ponnahdusikkuna näyttää lomakkeen peittokuvana valolaatikon sisällä. \nSitä ei voi minimoida vaan sulkea.",
    "popupHoverDescription": "Ponnahdusikkuna näyttää lomakkeen peittokuvana valolaatikon sisällä. \nSitä ei voi minimoida vaan sulkea.",
    "seconds": "sekuntia",
    "showAfter": "Näytä jälkeen",
    "showOnScrolling": "Näytä rullattaessa",
    "stickySidebar": "Tahmea sivupalkki",
    "stickySidebarDescription": "Sticky sivupalkki ilmestyy näytön reunaan, ja se voidaan pienentää välilehdeksi, joka tarttuu ikkunan sivuun",
    "stickySidebarHoverDescription": "Näkyvät näytön reunassa ja voidaan pienentää välilehdeksi, joka tarttuu ikkunan sivuun",
    "thirdPartyScriptAlertMessage": "HTML-lohkon sisällä olevat kolmannen osapuolen komentosarjat voivat tarjota tehokkaita toimintoja, mutta ne tuovat myös riskejä yksityisyydelle, turvallisuudelle, suorituskyvylle ja sivun käyttäytymiselle. \nSiksi suosittelemme, että tarkistat tämän skriptin sisällön ennen sen tallentamista lomakkeeseen.",
    "times": "ajat",
    "triggerType": "Liipaisimen tyyppi",
    "typeOfBuilder": "Lomake",
    "visit": "vierailla",
    "dragYourElementFromLeft": "Vedä elementit tänne vasemmalta",
    "enableTimezoneForm": "Tämän vaihtoehdon aktivointi tallentaa lomakkeen lähettäneen henkilön aikavyöhyketiedot",
    "formNotification": "Lomakeilmoitus",
    "copyFormLink": "Kopioi lomakkeen linkki",
    "openFormLink": "Avaa lomakkeen linkki",
    "unsavedAlertMessage": "Lomakkeessa on tallentamattomia töitä. \nHaluatko varmasti poistua ja hylätä kaikki tallentamattomat työt?",
    "style": {
      "formPadding": "Lomakkeen täyte"
    }
  },
  "survey": {
    "addSlide": "Lisää dia",
    "addSurveyElement": "Lisää kyselyelementti",
    "backButton": "Takaisin nappi",
    "backButtonTooltipMessage": "Takaisin-painike auttaa käyttäjää siirtämään edellistä diaa",
    "delete": "Poistaa",
    "disableAnimation": "Poista animaatio käytöstä",
    "disableAnimationTooltipMessage": "Tämän ottaminen käyttöön poistaa diasiirtymän animaation käytöstä",
    "disableAutoNavigation": "Poista automaattinen navigointi käytöstä",
    "disableAutoNavigationTooltipMessage": "Jos tämä on käytössä, kysely ei siirry automaattisesti seuraavaan diaan",
    "disqualifyAfterSubmit": "Hylkää lähettämisen jälkeen",
    "disqualifyImmediately": "Diskvalifioitu välittömästi",
    "editFooter": "Muokkaa alatunnistetta",
    "footer": {
      "backgroundFill": "Taustan täyttö",
      "buttonType": "Painikkeen tyyppi",
      "buttons": "Painikkeet",
      "enableNewFooter": "Ota uusi alatunniste käyttöön",
      "enableProgressBar": "Ota edistymispalkki käyttöön",
      "fillComplete": "Täytä Valmis",
      "fillInactive": "Täytä ei-aktiivinen",
      "fillNext": "Täytä (seuraava)",
      "fillPrevious": "Täytä (edellinen)",
      "fillSubmit": "Täytä (lähetä)",
      "footer": "Alatunniste",
      "footerHeight": "Alatunnisteen korkeus",
      "nextText": "Seuraava (teksti)",
      "position": "asema",
      "previousText": "Edellinen (teksti)",
      "stickToSurvey": "Pysy kyselyssä",
      "submitText": "Lähetä (teksti)",
      "textColor": "Tekstin väri",
      "footerType": "Alatunnisteen tyyppi",
      "stickToCard": "Kiinni korttiin",
      "stickToPage": "Kiinni sivuun"
    },
    "footerHTML": "Alatunnisteen HTML",
    "footerHTMLMessage": "Voit kirjoittaa mukautetun CSS:n (käyttäen tyylitunnistetta) ja HTML:n samaan laatikkoon",
    "integrateCopyLinkMessage": "Kopioi alla oleva linkki ja jaa se helposti missä haluat.",
    "integrateHeader": "Integroi kysely",
    "integrateSubHeader": "Käytä seuraavia vaihtoehtoja integroidaksesi mukautetun kyselysi verkkosivustollesi",
    "jumpTo": "Hypätä",
    "onSurveySubmission": "Kyselyn lähettämisestä",
    "oneQuestionAtTime": "Yksi kysymys kerrallaan",
    "progressBar": "Edistymispalkki",
    "progressBarTooltipMessage": "Tämän poistaminen käytöstä poistaa edistymispalkin kyselystä",
    "scrollToTop": "Vieritä ylös",
    "scrollToTopTooltipMessage": "Jos kysely on päällä, se vierittää seuraavan dian alkuun",
    "settings": "asetukset",
    "slideConflictAlertMessage": "Tämä dia sisältää useita kysymyksiä, joihin on asetettu logiikka. \nTämä voi aiheuttaa ei-toivottua toimintaa",
    "slideDeleteAlertMessage": "Haluatko varmasti poistaa tämän dian?",
    "slideDeleteBetweenAlertMessage": "Tämä järjestää muut diat uudelleen. Haluatko varmasti poistaa tämän dian?",
    "slideName": "Dian nimi",
    "slidePosition": "Liukun sijainti",
    "surveyElement": "Kyselyelementti",
    "surveyName": "Kyselyn nimi",
    "surveySettings": "Kyselyasetukset",
    "survyeStyle": "Kyselytyyli",
    "thirdPartyScriptAlertMessage": "HTML-lohkon sisällä olevat kolmannen osapuolen komentosarjat voivat tarjota tehokkaita toimintoja, mutta ne tuovat myös riskejä yksityisyydelle, turvallisuudelle, suorituskyvylle ja sivun käyttäytymiselle. \nSiksi suosittelemme, että tarkistat tämän skriptin sisällön ennen sen tallentamista kyselyyn.",
    "typeOfBuilder": "Kysely",
    "enableTimezoneSurvey": "Tämän vaihtoehdon aktivoiminen kaappaa kyselyn lähettäneen henkilön aikavyöhyketiedot",
    "surveyNotfication": "Kyselyilmoitus",
    "unsavedAlertMessage": "Kyselyssä on tallentamattomia töitä. \nHaluatko varmasti poistua ja hylätä kaikki tallentamattomat työt?",
    "style": {
      "surveyPadding": "Kyselyn täyte"
    }
  }
}
